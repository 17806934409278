import React, { createContext, useContext, useEffect, useState } from 'react';
import { Session, User } from '@supabase/supabase-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

interface AuthUser extends User {
  role?: 'admin' | 'business';
  full_name?: string;
}

export interface AuthContextType {
  session: Session | null;
  user: AuthUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  userRole: 'admin' | 'business' | null;
}

const AuthContext = createContext<AuthContextType>({
  session: null,
  user: null,
  isAuthenticated: false,
  isLoading: true,
  signIn: async () => {},
  signOut: async () => {},
  userRole: null,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<AuthUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState<'admin' | 'business' | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleAuthChange = async (session: Session | null) => {
    setIsLoading(true);
    try {
      if (session?.user) {
        const { data: profile, error: profileError } = await supabase
          .from('users')
          .select('*')
          .eq('user_id', session.user.id)
          .single();

        if (profileError) {
          console.error('Error fetching user profile:', profileError);
          await supabase.auth.signOut();
          setUser(null);
          setSession(null);
          setUserRole(null);
          navigate('/login');
          return;
        }

        if (profile) {
          setUser({
            ...session.user,
            role: profile.type,
            full_name: profile.full_name,
          });
          setUserRole(profile.type);
          setSession(session);

          // Redirect based on role if on auth pages
          const currentPath = location.pathname;
          if (['/login', '/register', '/'].includes(currentPath)) {
            const redirectPath = profile.type === 'admin' ? '/dashboard' : '/menu';
            navigate(redirectPath, { replace: true });
          }
        }
      } else {
        setUser(null);
        setSession(null);
        setUserRole(null);
        
        // Redirect to login if not on auth pages
        const currentPath = location.pathname;
        if (!['/login', '/register', '/'].includes(currentPath)) {
          navigate('/login', { replace: true });
        }
      }
    } catch (error) {
      console.error('Error handling auth change:', error);
      setUser(null);
      setSession(null);
      setUserRole(null);
      navigate('/login');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Forzar HTTPS
    if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
      window.location.href = window.location.href.replace('http:', 'https:');
      return;
    }

    // Check current session
    supabase.auth.getSession().then(({ data: { session } }) => {
      handleAuthChange(session);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      handleAuthChange(session);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        toast.error(error.message);
        throw error;
      }

      if (data.session) {
        const { data: profile, error: profileError } = await supabase
          .from('users')
          .select('*')
          .eq('user_id', data.session.user.id)
          .single();

        if (profileError) {
          toast.error('Error al obtener el perfil de usuario');
          throw profileError;
        }

        setUser({
          ...data.session.user,
          role: profile.type,
          full_name: profile.full_name,
        });
        setSession(data.session);
        setUserRole(profile.type);

        // Redirect based on role
        const redirectPath = profile.type === 'admin' ? '/dashboard' : '/menu';
        navigate(redirectPath, { replace: true });
        toast.success('Inicio de sesión exitoso');
      }
    } catch (error) {
      console.error('Error during sign in:', error);
      toast.error('Error al iniciar sesión');
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setIsLoading(true);
      await supabase.auth.signOut();
      setUser(null);
      setSession(null);
      setUserRole(null);
      navigate('/login', { replace: true });
      toast.success('Sesión cerrada exitosamente');
    } catch (error) {
      console.error('Error during sign out:', error);
      toast.error('Error al cerrar sesión');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        user,
        isAuthenticated: !!session,
        isLoading,
        signIn,
        signOut,
        userRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
