import React, { useState } from 'react';
import { ChevronDown, ChevronUp, HelpCircle } from 'lucide-react';
import { OrderStatus } from '../../types/orders';

interface OrdersTableProps {
  onSelectOrder: (orderId: string) => void;
}

export default function OrdersTable({ onSelectOrder }: OrdersTableProps) {
  const [sortField, setSortField] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;

  const orders = [
    {
      id: '#12345',
      customer: 'Juan Pérez',
      status: OrderStatus.DELIVERED,
      amount: '$156.000',
      date: '14 Mar 2024',
    },
    // Agregar más pedidos de ejemplo aquí
  ];

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const SortIcon = ({ field }: { field: string }) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? (
      <ChevronUp className="w-4 h-4" />
    ) : (
      <ChevronDown className="w-4 h-4" />
    );
  };

  const getStatusColor = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.NEW:
        return 'bg-blue-100 text-blue-800';
      case OrderStatus.PROCESSING:
        return 'bg-yellow-100 text-yellow-800';
      case OrderStatus.SHIPPING:
        return 'bg-purple-100 text-purple-800';
      case OrderStatus.DELIVERED:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b">
              {[
                { field: 'id', label: 'N° Pedido' },
                { field: 'customer', label: 'Cliente' },
                { field: 'status', label: 'Estado' },
                { field: 'amount', label: 'Monto' },
                { field: 'date', label: 'Fecha' },
              ].map(({ field, label }) => (
                <th
                  key={field}
                  className="pb-4 cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort(field)}
                >
                  <div className="flex items-center gap-1">
                    {label}
                    <SortIcon field={field} />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y">
            {orders.map((order) => (
              <tr
                key={order.id}
                className="text-sm hover:bg-gray-50 cursor-pointer"
                onClick={() => onSelectOrder(order.id)}
              >
                <td className="py-4">{order.id}</td>
                <td className="py-4">{order.customer}</td>
                <td className="py-4">
                  <span className={`px-2 py-1 rounded-full text-xs ${getStatusColor(order.status)}`}>
                    {order.status}
                  </span>
                </td>
                <td className="py-4">{order.amount}</td>
                <td className="py-4">{order.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="text-sm text-gray-500">
          Mostrando {ordersPerPage} de {orders.length} pedidos
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary"
          >
            Anterior
          </button>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage * ordersPerPage >= orders.length}
            className="btn btn-secondary"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
}