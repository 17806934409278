import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MarketingStats from '../components/marketing/MarketingStats';
import CampaignPerformance from '../components/marketing/CampaignPerformance';
import RecentCampaigns from '../components/marketing/RecentCampaigns';
import CreateCampaignModal from '../components/marketing/CreateCampaignModal';
import AutomationSettings from '../components/marketing/AutomationSettings';
import AudienceSegmentation from '../components/marketing/AudienceSegmentation';
import CampaignAnalytics from '../components/marketing/CampaignAnalytics';
import { Plus, HelpCircle } from 'lucide-react';

export default function Marketing() {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  return (
    <>
      <header className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">{t('marketing.title')}</h1>
            <p className="text-gray-500 mt-1">{t('marketing.subtitle')}</p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowTutorial(true)}
              className="btn btn-secondary flex items-center gap-2"
            >
              <HelpCircle className="w-4 h-4" />
              Tutorial
            </button>
            <button
              onClick={() => setShowCreateModal(true)}
              className="btn btn-primary flex items-center gap-2"
            >
              <Plus className="w-4 h-4" />
              Nueva Campaña
            </button>
          </div>
        </div>
      </header>

      <MarketingStats />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <CampaignPerformance />
        <RecentCampaigns />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <AutomationSettings />
        <AudienceSegmentation />
      </div>

      <div className="mt-6">
        <CampaignAnalytics />
      </div>

      {showCreateModal && (
        <CreateCampaignModal onClose={() => setShowCreateModal(false)} />
      )}
    </>
  );
}