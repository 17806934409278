import { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connectSocialMedia } from '../../lib/social-media';
import toast from 'react-hot-toast';

export default function SocialCallback() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const code = searchParams.get('code');
        const state = searchParams.get('state'); // La plataforma (facebook, instagram, twitter)
        const error = searchParams.get('error');

        if (error) {
          throw new Error(error);
        }

        if (!code || !state || !user) {
          throw new Error('Parámetros inválidos');
        }

        await connectSocialMedia(state, code, user.id);
        
        // Notificar al padre que la conexión fue exitosa
        if (window.opener) {
          window.opener.postMessage({ type: 'SOCIAL_CONNECTED', platform: state }, window.location.origin);
        }

        toast.success('Conexión exitosa');
        
        // Cerrar la ventana después de un breve momento
        setTimeout(() => {
          window.close();
        }, 1000);
      } catch (error) {
        console.error('Error en el callback:', error);
        toast.error('Error al conectar la red social');
        
        // Cerrar la ventana después de mostrar el error
        setTimeout(() => {
          window.close();
        }, 3000);
      }
    };

    handleCallback();
  }, [user, searchParams, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-lg font-medium text-gray-900">Procesando conexión...</h2>
        <p className="mt-2 text-sm text-gray-500">Por favor, no cierres esta ventana.</p>
      </div>
    </div>
  );
}
