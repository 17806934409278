import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import BusinessConfig from './pages/BusinessConfig';
import Login from './pages/Login';
import Register from './pages/Register';
import { useAuth } from './context/AuthContext';
import WhatsApp from './pages/WhatsApp';
import Orders from './pages/Orders';
import CRM from './pages/CRM';
import Appointments from './pages/Appointments';
import Marketing from './pages/Marketing';
import Surveys from './pages/Surveys';
import Quotes from './pages/Quotes';
import Subscription from './pages/Subscription';
import Settings from './pages/Settings';
import LandingPage from './pages/LandingPage';
import SocialCallback from './pages/auth/SocialCallback';

function Layout({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, userRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: location }, replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50">
      <Sidebar />
      <main className="flex-1 overflow-y-auto">
        <div className="container mx-auto px-4 py-8">
          {children}
        </div>
      </main>
    </div>
  );
}

function ProtectedRoute({ children, allowedRoles = ['admin', 'business'] }: { 
  children: React.ReactNode;
  allowedRoles?: ('admin' | 'business')[];
}) {
  const { isAuthenticated, isLoading, userRole } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (userRole && !allowedRoles.includes(userRole)) {
    return <Navigate to={userRole === 'admin' ? '/admin' : '/dashboard'} replace />;
  }

  return <>{children}</>;
}

function AuthRoute({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, userRole } = useAuth();
  const location = useLocation();

  if (isAuthenticated) {
    const from = location.state?.from?.pathname || (userRole === 'admin' ? '/admin' : '/dashboard');
    return <Navigate to={from} replace />;
  }

  return <>{children}</>;
}

export default function App() {
  const { isAuthenticated, userRole } = useAuth();

  return (
    <>
      <Toaster position="top-right" />
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={
          isAuthenticated ? 
          <Navigate to={userRole === 'admin' ? '/admin' : '/dashboard'} replace /> : 
          <LandingPage />
        } />
        <Route path="/auth/:platform/callback" element={<SocialCallback />} />

        {/* Rutas de autenticación */}
        <Route path="/login" element={<AuthRoute><Login /></AuthRoute>} />
        <Route path="/register" element={<AuthRoute><Register /></AuthRoute>} />

        {/* Rutas protegidas - Dashboard */}
        <Route path="/dashboard" element={
          <ProtectedRoute allowedRoles={['business']}>
            <Layout>
              <Dashboard />
            </Layout>
          </ProtectedRoute>
        } />

        {/* Rutas protegidas - Admin */}
        <Route path="/admin/*" element={
          <ProtectedRoute allowedRoles={['admin']}>
            <Layout>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="settings" element={<Settings />} />
                <Route path="business-config" element={<BusinessConfig />} />
              </Routes>
            </Layout>
          </ProtectedRoute>
        } />

        {/* Rutas protegidas - Usuario normal */}
        <Route path="/menu/*" element={
          <ProtectedRoute allowedRoles={['business']}>
            <Layout>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/whatsapp" element={<WhatsApp />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/crm" element={<CRM />} />
                <Route path="/appointments" element={<Appointments />} />
                <Route path="/marketing" element={<Marketing />} />
                <Route path="/surveys" element={<Surveys />} />
                <Route path="/quotes" element={<Quotes />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/settings" element={<Settings />} />
              </Routes>
            </Layout>
          </ProtectedRoute>
        } />

        {/* Ruta por defecto - redirige según el rol */}
        <Route path="*" element={
          isAuthenticated ? 
          <Navigate to={userRole === 'admin' ? '/admin' : '/menu'} replace /> : 
          <Navigate to="/" replace />
        } />
      </Routes>
    </>
  );
}