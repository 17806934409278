import React from 'react';
import { HelpCircle } from 'lucide-react';

const faqs = [
  {
    question: 'How do I connect my WhatsApp Business to the platform?',
    answer:
      'You can connect your WhatsApp Business in your profile settings. Enter your phone number, API token, and select the desired permissions. Click "Connect and Verify" to test the connection.',
  },
  {
    question: 'What should I do if the connection fails?',
    answer:
      'Verify that your phone number and API token are correct. Ensure you have access to the WhatsApp Business API. If the issue persists, contact our support team.',
  },
  {
    question: 'How do I set up an automatic welcome message?',
    answer:
      'In the Auto Responder tab, you can create welcome messages that are sent when a customer first contacts you. You can customize messages for different times of day.',
  },
  {
    question: 'Can I create automatic responses for common questions?',
    answer:
      'Yes! In the Auto Responder section, you can set up responses triggered by specific keywords. For example, set up a response for "hours" to automatically send your business hours.',
  },
  {
    question: 'How do I set up an interactive menu?',
    answer:
      'Use the Menu Builder to create options like "Product Information" or "Support". Each option can trigger a specific response or action, such as showing a message or opening a link.',
  },
];

export default function FAQSection() {
  return (
    <div className="space-y-6">
      <h2 className="text-lg font-medium text-gray-900 flex items-center gap-2">
        <HelpCircle className="w-5 h-5" />
        Frequently Asked Questions
      </h2>

      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border rounded-lg p-4 bg-white hover:bg-gray-50 transition-colors"
          >
            <h3 className="text-base font-medium text-gray-900 mb-2">
              {faq.question}
            </h3>
            <p className="text-sm text-gray-600">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
}