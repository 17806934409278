import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Save } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { saveAutoResponse, getAutoResponses, type AutoResponse } from '../../lib/whatsapp';
import toast from 'react-hot-toast';

export default function AutoResponder() {
  const { user } = useAuth();
  const [responses, setResponses] = useState<AutoResponse[]>([]);
  const [newResponse, setNewResponse] = useState({
    trigger: '',
    response: '',
    isActive: true
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      loadAutoResponses();
    }
  }, [user]);

  const loadAutoResponses = async () => {
    if (!user) return;

    try {
      const data = await getAutoResponses(user.id);
      setResponses(data);
    } catch (error) {
      console.error('Error loading auto responses:', error);
      toast.error('Error al cargar las respuestas automáticas');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !newResponse.trigger || !newResponse.response) return;

    setIsLoading(true);
    try {
      const result = await saveAutoResponse(user.id, newResponse);
      if (result.success) {
        toast.success('Respuesta automática guardada');
        setNewResponse({ trigger: '', response: '', isActive: true });
        loadAutoResponses();
      } else {
        throw new Error('Error al guardar la respuesta automática');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al guardar la respuesta automática');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    // Implementar eliminación
    toast.error('Función no implementada');
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Palabra o frase clave
          </label>
          <input
            type="text"
            value={newResponse.trigger}
            onChange={(e) =>
              setNewResponse({ ...newResponse, trigger: e.target.value })
            }
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="Ej: #horario"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Respuesta automática
          </label>
          <textarea
            value={newResponse.response}
            onChange={(e) =>
              setNewResponse({ ...newResponse, response: e.target.value })
            }
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="Escribe la respuesta que se enviará automáticamente"
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={newResponse.isActive}
            onChange={(e) =>
              setNewResponse({ ...newResponse, isActive: e.target.checked })
            }
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label className="ml-2 block text-sm text-gray-900">
            Activar esta respuesta automática
          </label>
        </div>

        <button
          type="submit"
          disabled={isLoading || !newResponse.trigger || !newResponse.response}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          <Plus className="h-4 w-4 mr-2" />
          Agregar Respuesta
        </button>
      </form>

      <div className="mt-8">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Respuestas Automáticas Configuradas
        </h3>
        <div className="space-y-4">
          {responses.map((response) => (
            <div
              key={response.id}
              className="bg-white shadow-sm rounded-lg border border-gray-200 p-4"
            >
              <div className="flex items-start justify-between">
                <div className="space-y-2">
                  <div>
                    <span className="text-sm font-medium text-gray-500">
                      Palabra clave:
                    </span>
                    <span className="ml-2 text-sm text-gray-900">
                      {response.trigger}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm font-medium text-gray-500">
                      Respuesta:
                    </span>
                    <p className="mt-1 text-sm text-gray-900">
                      {response.response}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={response.isActive}
                      onChange={() => {}} // Implementar cambio de estado
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-900">
                      {response.isActive ? 'Activa' : 'Inactiva'}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => handleDelete(response.id)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}