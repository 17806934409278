import React from 'react';
import { Calendar, Users, Star, ArrowRight } from 'lucide-react';

const surveys = [
  {
    id: 1,
    name: 'Satisfacción del Cliente Q1',
    status: 'active',
    responses: 234,
    endDate: '2024-03-31',
    rating: 4.5,
  },
  {
    id: 2,
    name: 'Feedback Nuevo Producto',
    status: 'paused',
    responses: 156,
    endDate: '2024-03-25',
    rating: 4.2,
  },
  {
    id: 3,
    name: 'Experiencia de Usuario',
    status: 'scheduled',
    responses: 0,
    endDate: '2024-04-15',
    rating: null,
  },
];

export default function RecentSurveys() {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-lg font-bold text-gray-900 mb-6">Encuestas Recientes</h2>
      <div className="space-y-4">
        {surveys.map((survey) => (
          <div
            key={survey.id}
            className="p-4 border rounded-lg hover:bg-gray-50 transition-colors cursor-pointer group"
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-medium text-gray-900 group-hover:text-indigo-600 transition-colors">
                  {survey.name}
                </h3>
                <div className="flex items-center gap-4 mt-2 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <Calendar className="w-4 h-4" />
                    <span>Hasta: {survey.endDate}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Users className="w-4 h-4" />
                    <span>{survey.responses} respuestas</span>
                  </div>
                  {survey.rating && (
                    <div className="flex items-center gap-1 text-yellow-500">
                      <Star className="w-4 h-4 fill-current" />
                      <span>{survey.rating}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span
                  className={`px-2 py-1 rounded-full text-xs ${
                    survey.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : survey.status === 'paused'
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-blue-100 text-blue-800'
                  }`}
                >
                  {survey.status === 'active'
                    ? 'Activa'
                    : survey.status === 'paused'
                    ? 'Pausada'
                    : 'Programada'}
                </span>
                <ArrowRight className="w-4 h-4 text-gray-400 group-hover:text-indigo-600 transition-colors" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}