import React, { useState } from 'react';
import { X, Mail, MessageSquare, Copy, Download } from 'lucide-react';

interface QuoteDetailsProps {
  quoteId: string;
  onClose: () => void;
}

export default function QuoteDetails({ quoteId, onClose }: QuoteDetailsProps) {
  const [currentStatus, setCurrentStatus] = useState('pending');

  const handleStatusChange = (newStatus: string) => {
    setCurrentStatus(newStatus);
    // Aquí iría la lógica para actualizar el estado y enviar notificaciones
  };

  const handleDuplicate = () => {
    // Aquí iría la lógica para duplicar la cotización
    console.log('Duplicando cotización:', quoteId);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900">
              Cotización #{quoteId}
            </h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <h3 className="font-medium text-gray-900 mb-2">Información del Cliente</h3>
              <div className="space-y-2">
                <p><span className="text-gray-500">Nombre:</span> Juan Pérez</p>
                <p><span className="text-gray-500">Email:</span> juan@email.com</p>
                <p><span className="text-gray-500">Teléfono:</span> +56 9 1234 5678</p>
              </div>
            </div>

            <div>
              <h3 className="font-medium text-gray-900 mb-2">Estado de la Cotización</h3>
              <select
                value={currentStatus}
                onChange={(e) => handleStatusChange(e.target.value)}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              >
                <option value="pending">Pendiente</option>
                <option value="accepted">Aceptada</option>
                <option value="rejected">Rechazada</option>
              </select>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="font-medium text-gray-900 mb-2">Detalles de la Cotización</h3>
            <table className="w-full">
              <thead>
                <tr className="text-left border-b">
                  <th className="pb-2">Descripción</th>
                  <th className="pb-2">Cantidad</th>
                  <th className="pb-2">Precio Unit.</th>
                  <th className="pb-2">Total</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                <tr>
                  <td className="py-2">Producto 1</td>
                  <td className="py-2">2</td>
                  <td className="py-2">$500.00</td>
                  <td className="py-2">$1,000.00</td>
                </tr>
                <tr>
                  <td className="py-2">Servicio 1</td>
                  <td className="py-2">1</td>
                  <td className="py-2">$750.00</td>
                  <td className="py-2">$750.00</td>
                </tr>
              </tbody>
              <tfoot className="border-t">
                <tr>
                  <td colSpan={3} className="py-2 text-right font-medium">Subtotal:</td>
                  <td className="py-2">$1,750.00</td>
                </tr>
                <tr>
                  <td colSpan={3} className="py-2 text-right font-medium">Impuesto (19%):</td>
                  <td className="py-2">$332.50</td>
                </tr>
                <tr>
                  <td colSpan={3} className="py-2 text-right font-bold">Total:</td>
                  <td className="py-2 font-bold">$2,082.50</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="mb-6">
            <h3 className="font-medium text-gray-900 mb-2">Notas</h3>
            <p className="text-gray-600">
              Cotización válida por 30 días. Incluye garantía de 12 meses.
            </p>
          </div>

          <div className="flex justify-between items-center pt-4 border-t">
            <div className="flex gap-2">
              <button className="btn btn-secondary flex items-center gap-2">
                <Mail className="w-4 h-4" />
                Enviar por Email
              </button>
              <button className="btn btn-secondary flex items-center gap-2">
                <MessageSquare className="w-4 h-4" />
                Enviar por WhatsApp
              </button>
            </div>
            <div className="flex gap-2">
              <button
                onClick={handleDuplicate}
                className="btn btn-secondary flex items-center gap-2"
              >
                <Copy className="w-4 h-4" />
                Duplicar
              </button>
              <button className="btn btn-secondary flex items-center gap-2">
                <Download className="w-4 h-4" />
                Descargar PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}