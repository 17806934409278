import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../lib/supabase';
import { connectSocialMedia, disconnectSocialMedia, checkSocialMediaConnection } from '../lib/social-media';
import toast from 'react-hot-toast';
import {
  Facebook,
  Instagram,
  Twitter,
  MapPin,
  Phone,
  MessageSquare,
  Loader2,
} from 'lucide-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { MenuEditor, MenuTemplate } from '../components/MenuBuilder';

interface BusinessConfig {
  id: string;
  business_name: string;
  address: string;
  phone: string;
  whatsapp: string;
  facebook_url: string;
  instagram_url: string;
  twitter_url: string;
  facebook_connected: boolean;
  instagram_connected: boolean;
  twitter_connected: boolean;
  faq: string;
}

interface Schedule {
  days: string[];
  startTime: string;
  endTime: string;
}

interface Session {
  id: string;
  user: {
    id: string;
    email: string;
  };
}

export default function BusinessConfig() {
  const { session } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [config, setConfig] = useState<BusinessConfig>({
    id: '',
    business_name: '',
    address: '',
    phone: '',
    whatsapp: '',
    facebook_url: '',
    instagram_url: '',
    twitter_url: '',
    facebook_connected: false,
    instagram_connected: false,
    twitter_connected: false,
    faq: ''
  });

  const [connections, setConnections] = useState({
    facebook: false,
    instagram: false,
    twitter: false
  });

  const [activeTemplate, setActiveTemplate] = useState<MenuTemplate>({
    id: '1234',
    name: 'Menú Principal',
    description: 'Menú principal del negocio',
    items: [],
    isActive: true,
    schedule: [{
      days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'],
      startTime: '09:00',
      endTime: '18:00'
    }] as Schedule[]
  });

  useEffect(() => {
    if (session) {
      loadBusinessConfig();
      loadConnections();
    }
  }, [session]);

  async function loadBusinessConfig() {
    try {
      const { data, error } = await supabase
        .from('business_config')
        .select('*')
        .eq('user_id', (session as Session)?.id)
        .single();

      if (error) throw error;

      if (data) {
        setConfig(data);
      }
    } catch (error) {
      console.error('Error al cargar la configuración:', error);
      toast.error('Error al cargar la configuración');
    } finally {
      setLoading(false);
    }
  }

  async function loadConnections() {
    if (!session) return;
    
    try {
      const platforms = ['facebook', 'instagram', 'twitter'];
      const connectionStates = await Promise.all(
        platforms.map(platform => checkSocialMediaConnection(platform, (session as Session).id))
      );

      setConnections({
        facebook: connectionStates[0],
        instagram: connectionStates[1],
        twitter: connectionStates[2]
      });
    } catch (error) {
      console.error('Error al cargar conexiones:', error);
      toast.error('Error al cargar el estado de las conexiones');
    }
  }

  async function saveBusinessConfig() {
    if (!session) return;

    setSaving(true);
    try {
      const { error } = await supabase
        .from('business_config')
        .upsert({
          user_id: (session as Session).id,
          ...config,
        });

      if (error) throw error;

      toast.success('Configuración guardada exitosamente');
    } catch (error) {
      console.error('Error al guardar la configuración:', error);
      toast.error('Error al guardar la configuración');
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return;

      if (event.data?.type === 'SOCIAL_CONNECTED') {
        const platform = event.data.platform;
        setConfig(prev => ({
          ...prev,
          [`${platform}_connected`]: true
        }));
        toast.success(`${platform} conectado exitosamente`);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleConnect = async (platform: string) => {
    if (!session) return;
    
    setLoading(true);
    try {
      await connectSocialMedia(platform, (session as Session).id);
      setConnections(prev => ({ ...prev, [platform]: true }));
      toast.success(`${platform} conectado exitosamente`);
    } catch (error) {
      console.error('Error al conectar:', error);
      toast.error(`Error al conectar ${platform}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnect = async (platform: string) => {
    if (!session) return;
    
    setLoading(true);
    try {
      await disconnectSocialMedia(platform, (session as Session).id);
      setConnections(prev => ({ ...prev, [platform]: false }));
      toast.success(`${platform} desconectado exitosamente`);
    } catch (error) {
      console.error('Error al desconectar:', error);
      toast.error(`Error al desconectar ${platform}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTemplate = async (template: MenuTemplate) => {
    if (!session) return;
    
    try {
      setLoading(true);
      const { error } = await supabase
        .from('menu_templates')
        .upsert({
          id: template.id,
          user_id: (session as Session).id,
          name: template.name,
          description: template.description,
          items: template.items,
          is_active: template.isActive,
          schedule: template.schedule
        });

      if (error) throw error;
      toast.success('Plantilla guardada exitosamente');
      setActiveTemplate(template);
    } catch (error) {
      console.error('Error al guardar la plantilla:', error);
      toast.error('Error al guardar la plantilla');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Configuración del Negocio</h1>
      
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Loader2 className="w-8 h-8 animate-spin" />
        </div>
      ) : (
        <div className="space-y-8">
          <section className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Información General</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nombre del Negocio
                </label>
                <input
                  type="text"
                  value={config.business_name}
                  onChange={(e) => setConfig({ ...config, business_name: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    <MapPin className="inline-block w-4 h-4 mr-1" />
                    Dirección
                  </label>
                  <input
                    type="text"
                    value={config.address}
                    onChange={(e) => setConfig({ ...config, address: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    <Phone className="inline-block w-4 h-4 mr-1" />
                    Teléfono
                  </label>
                  <input
                    type="tel"
                    value={config.phone}
                    onChange={(e) => setConfig({ ...config, phone: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    <MessageSquare className="inline-block w-4 h-4 mr-1" />
                    WhatsApp
                  </label>
                  <input
                    type="tel"
                    value={config.whatsapp}
                    onChange={(e) => setConfig({ ...config, whatsapp: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Redes Sociales</h2>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Facebook className="w-6 h-6 text-blue-600 mr-2" />
                  <span>Facebook</span>
                </div>
                <button
                  onClick={() => connections.facebook ? handleDisconnect('facebook') : handleConnect('facebook')}
                  className={`px-4 py-2 rounded-md ${
                    connections.facebook
                      ? 'bg-red-100 text-red-700 hover:bg-red-200'
                      : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                  }`}
                >
                  {connections.facebook ? 'Desconectar' : 'Conectar'}
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Instagram className="w-6 h-6 text-pink-600 mr-2" />
                  <span>Instagram</span>
                </div>
                <button
                  onClick={() => connections.instagram ? handleDisconnect('instagram') : handleConnect('instagram')}
                  className={`px-4 py-2 rounded-md ${
                    connections.instagram
                      ? 'bg-red-100 text-red-700 hover:bg-red-200'
                      : 'bg-pink-100 text-pink-700 hover:bg-pink-200'
                  }`}
                >
                  {connections.instagram ? 'Desconectar' : 'Conectar'}
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Twitter className="w-6 h-6 text-blue-400 mr-2" />
                  <span>Twitter</span>
                </div>
                <button
                  onClick={() => connections.twitter ? handleDisconnect('twitter') : handleConnect('twitter')}
                  className={`px-4 py-2 rounded-md ${
                    connections.twitter
                      ? 'bg-red-100 text-red-700 hover:bg-red-200'
                      : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                  }`}
                >
                  {connections.twitter ? 'Desconectar' : 'Conectar'}
                </button>
              </div>
            </div>
          </section>

          <section className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Menú y Horarios</h2>
            <DndProvider backend={HTML5Backend}>
              <MenuEditor
                template={activeTemplate}
                onSave={handleSaveTemplate}
                loading={loading}
              />
            </DndProvider>
          </section>

          <div className="flex justify-end">
            <button
              onClick={saveBusinessConfig}
              disabled={saving}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {saving ? (
                <>
                  <Loader2 className="inline-block w-4 h-4 mr-2 animate-spin" />
                  Guardando...
                </>
              ) : (
                'Guardar Cambios'
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
