import React from 'react';
import { Megaphone, Users, Mail, TrendingUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function MarketingStats() {
  const { t } = useTranslation();

  const stats = [
    {
      label: t('marketing.stats.activeCampaigns'),
      value: '12',
      change: '+2',
      icon: Megaphone,
      color: 'bg-blue-500',
    },
    {
      label: t('marketing.stats.targetAudience'),
      value: '2.4K',
      change: '+15%',
      icon: Users,
      color: 'bg-yellow-500',
    },
    {
      label: t('marketing.stats.messagesSent'),
      value: '8.5K',
      change: '+1.2K',
      icon: Mail,
      color: 'bg-purple-500',
    },
    {
      label: t('marketing.stats.conversionRate'),
      value: '24%',
      change: '+2.3%',
      icon: TrendingUp,
      color: 'bg-green-500',
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      {stats.map((stat) => (
        <div
          key={stat.label}
          className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow"
        >
          <div className="flex items-center justify-between mb-4">
            <div className={`${stat.color} p-3 rounded-lg`}>
              <stat.icon className="w-6 h-6 text-white" />
            </div>
            <span className="text-green-500 text-sm font-medium">{stat.change}</span>
          </div>
          <h3 className="text-gray-500 text-sm font-medium">{stat.label}</h3>
          <p className="text-2xl font-bold text-gray-900 mt-1">{stat.value}</p>
        </div>
      ))}
    </div>
  );
}