import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, HelpCircle } from 'lucide-react';
import QuoteStats from '../components/quotes/QuoteStats';
import QuotesTable from '../components/quotes/QuotesTable';
import CreateQuoteModal from '../components/quotes/CreateQuoteModal';
import QuoteDetails from '../components/quotes/QuoteDetails';

export default function Quotes() {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState<string | null>(null);

  return (
    <>
      <header className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">{t('quotes.title')}</h1>
            <p className="text-gray-500 mt-1">{t('quotes.subtitle')}</p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowCreateModal(true)}
              className="btn btn-primary flex items-center gap-2"
            >
              <Plus className="w-4 h-4" />
              Nueva Cotización
            </button>
          </div>
        </div>
      </header>

      <QuoteStats />

      <div className="bg-white rounded-xl shadow-sm">
        <QuotesTable onSelectQuote={setSelectedQuote} />
      </div>

      {showCreateModal && (
        <CreateQuoteModal onClose={() => setShowCreateModal(false)} />
      )}

      {selectedQuote && (
        <QuoteDetails
          quoteId={selectedQuote}
          onClose={() => setSelectedQuote(null)}
        />
      )}
    </>
  );
}