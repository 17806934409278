import React from 'react';
import { X, Copy } from 'lucide-react';

interface SurveyTemplatesProps {
  onClose: () => void;
}

const templates = [
  {
    id: 1,
    name: 'Satisfacción del Cliente',
    description: 'Evalúa la satisfacción general de tus clientes con tu servicio.',
    questions: 5,
  },
  {
    id: 2,
    name: 'Feedback de Producto',
    description: 'Recopila opiniones sobre un producto específico.',
    questions: 8,
  },
  {
    id: 3,
    name: 'NPS (Net Promoter Score)',
    description: 'Mide la lealtad de tus clientes.',
    questions: 3,
  },
];

export default function SurveyTemplates({ onClose }: SurveyTemplatesProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900">Plantillas de Encuesta</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="space-y-4">
            {templates.map((template) => (
              <div
                key={template.id}
                className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium text-gray-900">{template.name}</h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {template.description}
                    </p>
                    <p className="text-sm text-gray-400 mt-2">
                      {template.questions} preguntas
                    </p>
                  </div>
                  <button className="btn btn-secondary flex items-center gap-2">
                    <Copy className="w-4 h-4" />
                    Usar Plantilla
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}