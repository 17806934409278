import React, { useState } from 'react';
import { 
  ShoppingBag, 
  Package, 
  Truck, 
  CheckCircle, 
  RefreshCw,
  Download,
  Search,
  HelpCircle,
  Filter
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import OrdersTable from '../components/orders/OrdersTable';
import OrderFilters from '../components/orders/OrderFilters';
import OrderStats from '../components/orders/OrderStats';
import OrderDetails from '../components/orders/OrderDetails';
import { OrderStatus } from '../types/orders';

export default function Orders() {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    // Simular actualización de datos
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsRefreshing(false);
  };

  const handleExport = () => {
    // Implementar exportación a CSV/Excel
    console.log('Exportando datos...');
  };

  return (
    <>
      <header className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">{t('orders.title')}</h1>
            <p className="text-gray-500 mt-1">{t('orders.subtitle')}</p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleRefresh}
              className="btn btn-secondary flex items-center gap-2"
              disabled={isRefreshing}
            >
              <RefreshCw className={`w-4 h-4 ${isRefreshing ? 'animate-spin' : ''}`} />
              Actualizar
            </button>
            <button
              onClick={handleExport}
              className="btn btn-secondary flex items-center gap-2"
            >
              <Download className="w-4 h-4" />
              Exportar
            </button>
          </div>
        </div>
      </header>

      <OrderStats />

      <div className="bg-white rounded-xl shadow-sm mb-6">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center gap-4">
              <h2 className="text-xl font-bold text-gray-900">Pedidos Recientes</h2>
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="btn btn-secondary flex items-center gap-2"
              >
                <Filter className="w-4 h-4" />
                Filtros
              </button>
            </div>
            <div className="flex items-center gap-2">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Buscar pedidos..."
                  className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
                <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              </div>
            </div>
          </div>

          {showFilters && <OrderFilters />}

          <OrdersTable onSelectOrder={setSelectedOrder} />
        </div>
      </div>

      {selectedOrder && (
        <OrderDetails
          orderId={selectedOrder}
          onClose={() => setSelectedOrder(null)}
        />
      )}
    </>
  );
}