import React, { useState } from 'react';
import { Users, Filter, Save, Plus } from 'lucide-react';

interface Segment {
  id: number;
  name: string;
  criteria: {
    purchaseFrequency?: string;
    status?: string;
    location?: string;
    lastInteraction?: string;
  };
  count: number;
}

export default function AudienceSegmentation() {
  const [segments, setSegments] = useState<Segment[]>([
    {
      id: 1,
      name: 'Clientes VIP',
      criteria: {
        purchaseFrequency: 'high',
        status: 'active',
      },
      count: 234,
    },
    {
      id: 2,
      name: 'Nuevos Clientes',
      criteria: {
        lastInteraction: 'last_30_days',
      },
      count: 567,
    },
  ]);

  const [showNewSegment, setShowNewSegment] = useState(false);

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-bold text-gray-900">Segmentación de Audiencia</h2>
        <button
          onClick={() => setShowNewSegment(true)}
          className="btn btn-primary flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Nuevo Segmento
        </button>
      </div>

      <div className="space-y-4">
        {segments.map((segment) => (
          <div
            key={segment.id}
            className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-start justify-between">
              <div>
                <h3 className="font-medium text-gray-900 flex items-center gap-2">
                  <Users className="w-4 h-4" />
                  {segment.name}
                </h3>
                <div className="mt-2 space-y-1">
                  {segment.criteria.purchaseFrequency && (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2">
                      Compras frecuentes
                    </span>
                  )}
                  {segment.criteria.status && (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800 mr-2">
                      Activos
                    </span>
                  )}
                  {segment.criteria.lastInteraction && (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                      Últimos 30 días
                    </span>
                  )}
                </div>
              </div>
              <div className="text-right">
                <span className="text-sm text-gray-500">Audiencia</span>
                <p className="text-lg font-bold text-gray-900">{segment.count}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showNewSegment && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-xl shadow-xl w-full max-w-md p-6">
            <h3 className="text-lg font-bold text-gray-900 mb-4">Crear Nuevo Segmento</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nombre del Segmento
                </label>
                <input
                  type="text"
                  className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                  placeholder="Ej: Clientes Premium"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Criterios
                </label>
                <div className="space-y-2">
                  <select className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500">
                    <option value="">Frecuencia de Compra</option>
                    <option value="high">Alta</option>
                    <option value="medium">Media</option>
                    <option value="low">Baja</option>
                  </select>
                  <select className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500">
                    <option value="">Estado</option>
                    <option value="active">Activo</option>
                    <option value="inactive">Inactivo</option>
                  </select>
                  <select className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500">
                    <option value="">Última Interacción</option>
                    <option value="last_7_days">Últimos 7 días</option>
                    <option value="last_30_days">Últimos 30 días</option>
                    <option value="last_90_days">Últimos 90 días</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-6">
              <button
                onClick={() => setShowNewSegment(false)}
                className="btn btn-secondary"
              >
                Cancelar
              </button>
              <button className="btn btn-primary flex items-center gap-2">
                <Save className="w-4 h-4" />
                Guardar Segmento
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}