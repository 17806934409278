import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Edit2, Trash2, ChevronRight, MessageCircle, Image, Link } from 'lucide-react';
import { MenuItem } from './types';

interface MenuNodeProps {
  item: MenuItem;
  index: number;
  depth: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onEdit: (item: MenuItem) => void;
  onDelete: (item: MenuItem) => void;
}

export function MenuNode({ item, index, depth, moveItem, onEdit, onDelete }: MenuNodeProps) {
  const [{ isDragging }, drag] = useDrag({
    type: 'MENU_ITEM',
    item: { index, type: 'MENU_ITEM' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'MENU_ITEM',
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const getIcon = () => {
    switch (item.type) {
      case 'text':
        return <MessageCircle className="w-4 h-4" />;
      case 'image':
        return <Image className="w-4 h-4" />;
      case 'link':
        return <Link className="w-4 h-4" />;
      default:
        return <MessageCircle className="w-4 h-4" />;
    }
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ marginLeft: `${depth * 20}px` }}
      className={`flex items-center gap-2 p-3 mb-2 rounded-lg border ${
        isDragging ? 'opacity-50 bg-gray-100' : 'bg-white'
      } ${isOver ? 'border-blue-500' : 'border-gray-200'}`}
    >
      <div className="flex items-center gap-2 flex-1">
        {getIcon()}
        <span className="font-medium">{item.title}</span>
        {item.children?.length > 0 && (
          <ChevronRight className="w-4 h-4 text-gray-400" />
        )}
      </div>
      
      <div className="flex items-center gap-2">
        <button
          onClick={() => onEdit(item)}
          className="p-1 text-gray-500 hover:text-blue-500 rounded-md hover:bg-blue-50"
        >
          <Edit2 className="w-4 h-4" />
        </button>
        <button
          onClick={() => onDelete(item)}
          className="p-1 text-gray-500 hover:text-red-500 rounded-md hover:bg-red-50"
        >
          <Trash2 className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}
