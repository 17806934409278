import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  MessageSquare, 
  Users, 
  BarChart2, 
  Star, 
  ChevronRight, 
  Facebook, 
  Instagram, 
  Linkedin,
  Twitter,
  MessageCircle
} from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const plans = [
  {
    name: 'Emprendedor',
    price: {
      monthly: 29900,
      annual: 299900,
    },
    features: [
      'Hasta 1,000 mensajes/mes',
      'Panel de usuario básico',
      'Gestión de clientes',
      'Cotizaciones básicas',
      'Soporte por email'
    ],
    limit: '1 usuario'
  },
  {
    name: 'PYME',
    price: {
      monthly: 49900,
      annual: 499900,
    },
    features: [
      'Hasta 5,000 mensajes/mes',
      'Panel de usuario completo',
      'Marketing automatizado',
      'Encuestas y feedback',
      'Soporte prioritario'
    ],
    limit: '5 usuarios',
    popular: true
  },
  {
    name: 'Empresa',
    price: {
      monthly: 99900,
      annual: 999900,
    },
    features: [
      'Mensajes ilimitados',
      'Panel personalizado',
      'API acceso completo',
      'Integraciones avanzadas',
      'Soporte 24/7'
    ],
    limit: 'Usuarios ilimitados'
  }
];

const testimonials = [
  {
    name: 'Ana Martínez',
    role: 'Gerente de Marketing, TechCorp',
    content: 'FlowUp ha transformado completamente nuestra comunicación con clientes. La automatización nos ahorra horas cada semana.',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150&h=150&fit=crop'
  },
  {
    name: 'Carlos Ruiz',
    role: 'Dueño, Café Aurora',
    content: 'La gestión de pedidos a través de WhatsApp nunca fue tan fácil. Nuestras ventas aumentaron un 40% desde que usamos FlowUp.',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=150&h=150&fit=crop'
  },
  {
    name: 'María González',
    role: 'Directora de Ventas, RetailPro',
    content: 'El seguimiento de clientes y las analíticas nos han permitido mejorar significativamente nuestro servicio al cliente.',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=150&h=150&fit=crop'
  }
];

export default function LandingPage() {
  const [isAnnual, setIsAnnual] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/56963925075', '_blank');
  };

  const handleLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/login');
  };

  const handleRegister = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/register');
  };

  return (
    <div className="min-h-screen">
      {/* Header */}
      <header className="bg-white shadow-sm fixed w-full z-50">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <MessageSquare className="w-8 h-8 text-indigo-600" />
            <span className="text-xl font-bold">FlowChat</span>
          </div>
          
          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden text-gray-600"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              {isMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-6">
            <a href="#funcionalidades" className="text-gray-600 hover:text-gray-900">Funcionalidades</a>
            <a href="#precios" className="text-gray-600 hover:text-gray-900">Precios</a>
            <a href="#contacto" className="text-gray-600 hover:text-gray-900">Contacto</a>
            <button 
              onClick={handleLogin}
              className="text-gray-600 hover:text-gray-900"
            >
              Iniciar Sesión
            </button>
            <button
              onClick={handleRegister}
              className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors text-sm"
            >
              Comenzar Gratis
            </button>
          </div>
        </nav>

        {/* Mobile Menu */}
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'} bg-white border-t`}>
          <div className="px-4 py-2 space-y-2">
            <a href="#funcionalidades" className="block py-2 text-gray-600 hover:text-gray-900">Funcionalidades</a>
            <a href="#precios" className="block py-2 text-gray-600 hover:text-gray-900">Precios</a>
            <a href="#contacto" className="block py-2 text-gray-600 hover:text-gray-900">Contacto</a>
            <button 
              onClick={handleLogin}
              className="block w-full text-left py-2 text-gray-600 hover:text-gray-900"
            >
              Iniciar Sesión
            </button>
            <button
              onClick={handleRegister}
              className="block w-full text-left py-2 text-indigo-600 hover:text-indigo-700"
            >
              Comenzar Gratis
            </button>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="pt-32 pb-16 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
              Gestiona tu negocio con WhatsApp
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Automatiza la comunicación, gestiona pedidos y aumenta tus ventas con la plataforma más completa de WhatsApp Business.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={handleWhatsAppClick}
                className="bg-green-600 text-white px-8 py-3 rounded-lg hover:bg-green-700 transition-colors text-lg font-medium flex items-center justify-center gap-2"
              >
                <FontAwesomeIcon icon={faWhatsapp} className="w-6 h-6" />
                Contactar por WhatsApp
              </button>
              <a
                href="#contacto"
                className="bg-indigo-600 text-white px-8 py-3 rounded-lg hover:bg-indigo-700 transition-colors text-lg font-medium"
              >
                Solicitar Demo
              </a>
            </div>
            <p className="text-sm text-gray-500 mt-4">
              Un proyecto de Agencia Kreatos Digital Chile
            </p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="funcionalidades" className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900">Todo lo que necesitas en un solo lugar</h2>
            <p className="mt-4 text-xl text-gray-600">
              Herramientas poderosas para hacer crecer tu negocio
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
                <MessageSquare className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Chat Unificado</h3>
              <p className="text-gray-600">
                Gestiona todas tus conversaciones de WhatsApp en un solo lugar.
              </p>
            </div>
            <div className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
                <Users className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Gestión de Clientes</h3>
              <p className="text-gray-600">
                Organiza y segmenta tu base de clientes para campañas efectivas.
              </p>
            </div>
            <div className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
                <BarChart2 className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Analíticas Avanzadas</h3>
              <p className="text-gray-600">
                Mide y optimiza el rendimiento de tus campañas de WhatsApp.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="precios" className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900">Planes que se adaptan a tu negocio</h2>
            <p className="mt-4 text-xl text-gray-600">
              Elige el plan perfecto para hacer crecer tu negocio
            </p>
            <div className="mt-6 flex items-center justify-center gap-4">
              <span className={`text-sm ${!isAnnual ? 'text-gray-900' : 'text-gray-500'}`}>Mensual</span>
              <button
                onClick={() => setIsAnnual(!isAnnual)}
                className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                  isAnnual ? 'bg-indigo-600' : 'bg-gray-200'
                }`}
              >
                <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                    isAnnual ? 'translate-x-6' : 'translate-x-1'
                  }`}
                />
              </button>
              <span className={`text-sm ${isAnnual ? 'text-gray-900' : 'text-gray-500'}`}>
                Anual <span className="text-green-600">(20% descuento)</span>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {plans.map((plan) => (
              <div
                key={plan.name}
                className={`bg-white rounded-xl shadow-sm p-8 relative ${
                  plan.popular ? 'border-2 border-indigo-600 shadow-md' : ''
                }`}
              >
                {plan.popular && (
                  <span className="absolute top-0 right-8 -translate-y-1/2 bg-indigo-600 text-white px-3 py-1 rounded-full text-sm">
                    Más Popular
                  </span>
                )}
                <h3 className="text-xl font-bold text-gray-900 mb-4">{plan.name}</h3>
                <div className="mb-6">
                  <span className="text-4xl font-bold text-gray-900">
                    ${((isAnnual ? plan.price.annual / 12 : plan.price.monthly) / 1000).toFixed(3)}
                  </span>
                  <span className="text-gray-600">/mes</span>
                  {isAnnual && (
                    <p className="text-sm text-gray-500 mt-1">Facturado anualmente</p>
                  )}
                </div>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center gap-2">
                      <Star className="w-5 h-5 text-indigo-600" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                  <li className="flex items-center gap-2 font-medium">
                    <Users className="w-5 h-5 text-indigo-600" />
                    <span className="text-gray-900">{plan.limit}</span>
                  </li>
                </ul>
                <a
                  href="#contacto"
                  className={`block text-center py-2 px-4 rounded-lg transition-colors ${
                    plan.popular
                      ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                      : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                  }`}
                >
                  Contactar Ventas
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900">Lo que dicen nuestros clientes</h2>
            <p className="mt-4 text-gray-500">
              Empresas que han transformado su negocio con FlowUp
            </p>
          </div>
          <div className="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.name}
                className="bg-gray-50 p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="flex items-center gap-4 mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <div>
                    <h3 className="font-medium text-gray-900">{testimonial.name}</h3>
                    <p className="text-sm text-gray-500">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-gray-600 italic">"{testimonial.content}"</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Client Carousel Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900">Confían en nosotros</h2>
            <p className="mt-4 text-gray-500">
              Empresas líderes en Chile que utilizan FlowUp
            </p>
          </div>
          <div className="flex items-center justify-between gap-8 overflow-hidden">
            <div className="flex animate-scroll">
              {[
                { name: 'Falabella', logo: 'https://logo.clearbit.com/falabella.com' },
                { name: 'Ripley', logo: 'https://logo.clearbit.com/ripley.cl' },
                { name: 'Paris', logo: 'https://logo.clearbit.com/paris.cl' },
                { name: 'Sodimac', logo: 'https://logo.clearbit.com/sodimac.cl' },
                { name: 'Easy', logo: 'https://logo.clearbit.com/easy.cl' },
                { name: 'Jumbo', logo: 'https://logo.clearbit.com/jumbo.cl' }
              ].map((client) => (
                <div
                  key={client.name}
                  className="flex-shrink-0 mx-8 grayscale hover:grayscale-0 transition-all"
                >
                  <img
                    src={client.logo}
                    alt={client.name}
                    className="h-12 w-auto object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <div className="flex items-center gap-2 mb-4">
                <MessageSquare className="w-8 h-8 text-indigo-400" />
                <span className="text-xl font-bold">FlowChat</span>
              </div>
              <p className="text-gray-400 mb-4">
                Un proyecto de Agencia Kreatos Digital Chile
              </p>
              <div className="flex gap-4">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                  <Facebook className="w-6 h-6" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                  <Instagram className="w-6 h-6" />
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                  <Linkedin className="w-6 h-6" />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                  <Twitter className="w-6 h-6" />
                </a>
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Producto</h3>
              <ul className="space-y-2">
                <li><a href="#funcionalidades" className="text-gray-400 hover:text-white">Funcionalidades</a></li>
                <li><a href="#precios" className="text-gray-400 hover:text-white">Precios</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Guías</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">API</a></li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Empresa</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Sobre Nosotros</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Blog</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Empleos</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Prensa</a></li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Legal</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Privacidad</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Términos</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Cookies</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Licencias</a></li>
              </ul>
            </div>
          </div>

          <div className="border-t border-gray-800 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400"> 2024 FlowChat. Todos los derechos reservados.</p>
            <div className="flex gap-4 mt-4 md:mt-0">
              <a href="#" className="text-gray-400 hover:text-white">
                <span className="sr-only">Facebook</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <span className="sr-only">Twitter</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <span className="sr-only">LinkedIn</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" clipRule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>

      {/* WhatsApp Floating Button */}
      <button
        onClick={handleWhatsAppClick}
        className="fixed bottom-4 right-4 bg-green-600 text-white p-4 rounded-full shadow-lg hover:bg-green-700 transition-colors z-50"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="w-6 h-6" />
      </button>
    </div>
  );
}