export default {
  nav: {
    dashboard: 'Dashboard',
    whatsapp: 'WhatsApp',
    orders: 'Orders',
    crm: 'CRM',
    appointments: 'Appointments',
    marketing: 'Marketing',
    surveys: 'Surveys',
    quotes: 'Quotes',
    payments: 'Payments',
    settings: 'Settings',
    logout: 'Sign Out'
  },
  dashboard: {
    welcome: 'Welcome back, {name}!',
    subtitle: "Here's what's happening with your business today.",
    stats: {
      totalCustomers: 'Total Customers',
      activeOrders: 'Active Orders',
      todayAppointments: "Today's Appointments",
      revenue: 'Revenue'
    },
    recentActivity: {
      title: 'Recent Activity',
      newMessage: 'New WhatsApp Message',
      newOrder: 'New Order Received',
      appointmentConfirmed: 'Appointment Confirmed',
      newCustomer: 'New Customer'
    }
  },
  whatsapp: {
    title: 'WhatsApp Integration',
    subtitle: 'Configure your WhatsApp Business account',
    setup: {
      title: 'Setup',
      phoneNumber: 'Phone Number',
      apiToken: 'API Token',
      permissions: 'Permissions',
      connect: 'Connect and Verify'
    }
  },
  orders: {
    title: 'Orders Management',
    subtitle: 'Track and manage your orders',
    stats: {
      newOrders: 'New Orders',
      processing: 'Processing',
      shipping: 'Shipping',
      delivered: 'Delivered'
    }
  },
  crm: {
    title: 'Customer Relationship Management',
    subtitle: 'Manage and analyze customer interactions',
    stats: {
      totalCustomers: 'Total Customers',
      vipCustomers: 'VIP Customers',
      newThisMonth: 'New This Month',
      followUps: 'Follow-ups'
    }
  },
  appointments: {
    title: 'Appointments',
    subtitle: 'Schedule and manage your appointments',
    stats: {
      todayAppointments: "Today's Appointments",
      pending: 'Pending',
      totalClients: 'Total Clients',
      completedToday: 'Completed Today'
    }
  },
  marketing: {
    title: 'Marketing Automation',
    subtitle: 'Manage your campaigns and automations',
    stats: {
      activeCampaigns: 'Active Campaigns',
      targetAudience: 'Target Audience',
      messagesSent: 'Messages Sent',
      conversionRate: 'Conversion Rate'
    }
  },
  surveys: {
    title: 'Surveys & Feedback',
    subtitle: 'Create and manage customer surveys',
    stats: {
      activeSurveys: 'Active Surveys',
      totalResponses: 'Total Responses',
      completionRate: 'Completion Rate',
      avgRating: 'Average Rating'
    }
  },
  quotes: {
    title: 'Quotes Management',
    subtitle: 'Create and track customer quotes',
    stats: {
      totalQuotes: 'Total Quotes',
      value: 'Value',
      pending: 'Pending',
      accepted: 'Accepted'
    }
  },
  payments: {
    title: 'Payments',
    subtitle: 'Track and manage payments',
    stats: {
      totalRevenue: 'Total Revenue',
      pending: 'Pending',
      successful: 'Successful',
      failed: 'Failed'
    }
  },
  settings: {
    title: 'Settings',
    subtitle: 'Manage your account and preferences',
    sections: {
      profile: 'Profile',
      notifications: 'Notifications',
      security: 'Security',
      appearance: 'Appearance'
    }
  },
  common: {
    loading: 'Loading...',
    error: 'An error has occurred',
    save: 'Save',
    cancel: 'Cancel',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    status: {
      active: 'Active',
      inactive: 'Inactive',
      pending: 'Pending',
      completed: 'Completed'
    }
  }
};