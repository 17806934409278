import React from 'react';
import { BarChart, Download } from 'lucide-react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function CampaignAnalytics() {
  const data = {
    labels: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
    datasets: [
      {
        label: 'Tasa de Apertura',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
      {
        label: 'Tasa de Clics',
        data: [28, 48, 40, 19, 86, 27, 90],
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
      {
        label: 'Conversiones',
        data: [12, 19, 3, 5, 2, 3, 7],
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-lg font-bold text-gray-900">Analíticas Detalladas</h2>
          <p className="text-sm text-gray-500">Últimos 7 días</p>
        </div>
        <button className="btn btn-secondary flex items-center gap-2">
          <Download className="w-4 h-4" />
          Exportar Datos
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
        {[
          { label: 'Tasa de Apertura', value: '45%', change: '+5%' },
          { label: 'Tasa de Clics', value: '28%', change: '+2%' },
          { label: 'Conversiones', value: '12%', change: '+1.5%' },
        ].map((metric) => (
          <div key={metric.label} className="p-4 border rounded-lg">
            <p className="text-sm text-gray-500">{metric.label}</p>
            <div className="flex items-end gap-2">
              <p className="text-2xl font-bold text-gray-900">{metric.value}</p>
              <span className="text-sm text-green-500">{metric.change}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="h-80">
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}