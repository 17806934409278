import React, { useState } from 'react';
import { Search, Filter, ChevronDown, ChevronUp, Download } from 'lucide-react';

interface QuotesTableProps {
  onSelectQuote: (quoteId: string) => void;
}

export default function QuotesTable({ onSelectQuote }: QuotesTableProps) {
  const [sortField, setSortField] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const quotesPerPage = 10;

  const quotes = [
    {
      id: 'COT-001',
      client: 'Juan Pérez',
      amount: '$1,234.56',
      status: 'pending',
      date: '2024-03-14',
    },
    {
      id: 'COT-002',
      client: 'María González',
      amount: '$2,345.67',
      status: 'accepted',
      date: '2024-03-13',
    },
    {
      id: 'COT-003',
      client: 'Carlos Rodríguez',
      amount: '$3,456.78',
      status: 'rejected',
      date: '2024-03-12',
    },
  ];

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const SortIcon = ({ field }: { field: string }) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? (
      <ChevronUp className="w-4 h-4" />
    ) : (
      <ChevronDown className="w-4 h-4" />
    );
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Buscar cotizaciones..."
              className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
            />
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="btn btn-secondary flex items-center gap-2"
          >
            <Filter className="w-4 h-4" />
            Filtros
          </button>
        </div>
        <button className="btn btn-secondary flex items-center gap-2">
          <Download className="w-4 h-4" />
          Exportar
        </button>
      </div>

      {showFilters && (
        <div className="mb-4 p-4 border rounded-lg bg-gray-50">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Estado
              </label>
              <select className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500">
                <option value="">Todos</option>
                <option value="pending">Pendiente</option>
                <option value="accepted">Aceptada</option>
                <option value="rejected">Rechazada</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fecha Desde
              </label>
              <input
                type="date"
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fecha Hasta
              </label>
              <input
                type="date"
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b">
              {[
                { field: 'id', label: 'N° Cotización' },
                { field: 'client', label: 'Cliente' },
                { field: 'amount', label: 'Monto' },
                { field: 'status', label: 'Estado' },
                { field: 'date', label: 'Fecha' },
              ].map(({ field, label }) => (
                <th
                  key={field}
                  className="pb-4 cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort(field)}
                >
                  <div className="flex items-center gap-1">
                    {label}
                    <SortIcon field={field} />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y">
            {quotes.map((quote) => (
              <tr
                key={quote.id}
                className="text-sm hover:bg-gray-50 cursor-pointer"
                onClick={() => onSelectQuote(quote.id)}
              >
                <td className="py-4">{quote.id}</td>
                <td className="py-4">{quote.client}</td>
                <td className="py-4">{quote.amount}</td>
                <td className="py-4">
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      quote.status === 'pending'
                        ? 'bg-yellow-100 text-yellow-800'
                        : quote.status === 'accepted'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {quote.status === 'pending'
                      ? 'Pendiente'
                      : quote.status === 'accepted'
                      ? 'Aceptada'
                      : 'Rechazada'}
                  </span>
                </td>
                <td className="py-4">{quote.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="text-sm text-gray-500">
          Mostrando {quotesPerPage} de {quotes.length} cotizaciones
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary"
          >
            Anterior
          </button>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage * quotesPerPage >= quotes.length}
            className="btn btn-secondary"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
}