import React, { useState } from 'react';
import { X, Calendar, Users, MessageSquare, Target } from 'lucide-react';
import { useForm } from 'react-hook-form';

interface CreateCampaignModalProps {
  onClose: () => void;
}

interface CampaignFormData {
  name: string;
  audience: string[];
  message: string;
  scheduledDate: string;
  objective: string;
}

export default function CreateCampaignModal({ onClose }: CreateCampaignModalProps) {
  const { register, handleSubmit, watch } = useForm<CampaignFormData>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: CampaignFormData) => {
    try {
      setIsSubmitting(true);
      // Aquí iría la lógica para crear la campaña
      console.log('Campaña creada:', data);
      onClose();
    } catch (error) {
      console.error('Error al crear la campaña:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900">Crear Nueva Campaña</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nombre de la Campaña
              </label>
              <input
                type="text"
                {...register('name', { required: true })}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                placeholder="Ej: Campaña Verano 2024"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Audiencia Objetivo
              </label>
              <select
                multiple
                {...register('audience', { required: true })}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              >
                <option value="all">Todos los clientes</option>
                <option value="vip">Clientes VIP</option>
                <option value="new">Nuevos clientes</option>
                <option value="inactive">Clientes inactivos</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Mensaje de la Campaña
              </label>
              <textarea
                {...register('message', { required: true })}
                rows={4}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                placeholder="Escribe el mensaje de tu campaña..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fecha de Envío
              </label>
              <input
                type="datetime-local"
                {...register('scheduledDate', { required: true })}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Objetivo de la Campaña
              </label>
              <select
                {...register('objective', { required: true })}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              >
                <option value="sales">Aumentar ventas</option>
                <option value="engagement">Mejorar engagement</option>
                <option value="awareness">Reconocimiento de marca</option>
                <option value="retention">Retención de clientes</option>
              </select>
            </div>

            <div className="flex justify-end gap-2 pt-4 border-t">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                disabled={isSubmitting}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Creando...' : 'Crear Campaña'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}