import React from 'react';

export default function AppointmentCalendar() {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">Calendar</h2>
        <button className="btn btn-primary">+ New Appointment</button>
      </div>
      
      <div className="grid grid-cols-7 gap-1">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="text-center text-sm font-medium text-gray-500 py-2">
            {day}
          </div>
        ))}
        {Array.from({ length: 35 }).map((_, i) => (
          <div
            key={i}
            className="aspect-square border rounded-lg p-1 cursor-pointer hover:bg-gray-50"
          >
            <div className="text-sm">{i + 1}</div>
            {i === 15 && (
              <div className="text-xs bg-blue-100 text-blue-800 rounded px-1 mt-1">
                3 events
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}