import React, { useState } from 'react';
import { X, CreditCard, Building2 } from 'lucide-react';

interface PaymentMethodModalProps {
  type: 'card' | 'transfer' | null;
  onClose: () => void;
}

export default function PaymentMethodModal({ type, onClose }: PaymentMethodModalProps) {
  const [formData, setFormData] = useState({
    cardNumber: '',
    cardName: '',
    expiry: '',
    cvv: '',
  });

  const bankDetails = {
    bank: 'Banco Estado',
    accountType: 'Cuenta Corriente',
    accountNumber: '00-000-00-00000000',
    rut: '76.XXX.XXX-X',
    name: 'FlowUp SpA',
    email: 'pagos@flowup.cl'
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Aquí iría la lógica de procesamiento del pago
    console.log('Procesando pago:', formData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900">
              {type === 'card' ? 'Pago con Tarjeta' : 'Transferencia Bancaria'}
            </h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>

          {type === 'card' ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Número de Tarjeta
                </label>
                <input
                  type="text"
                  value={formData.cardNumber}
                  onChange={(e) => setFormData({ ...formData, cardNumber: e.target.value })}
                  className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                  placeholder="1234 5678 9012 3456"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nombre en la Tarjeta
                </label>
                <input
                  type="text"
                  value={formData.cardName}
                  onChange={(e) => setFormData({ ...formData, cardName: e.target.value })}
                  className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                  placeholder="JUAN PEREZ"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Fecha de Expiración
                  </label>
                  <input
                    type="text"
                    value={formData.expiry}
                    onChange={(e) => setFormData({ ...formData, expiry: e.target.value })}
                    className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                    placeholder="MM/AA"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    CVV
                  </label>
                  <input
                    type="text"
                    value={formData.cvv}
                    onChange={(e) => setFormData({ ...formData, cvv: e.target.value })}
                    className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                    placeholder="123"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="w-full btn btn-primary mt-6"
              >
                Pagar
              </button>
            </form>
          ) : (
            <div className="space-y-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="font-medium text-gray-900 mb-2">Datos Bancarios</h3>
                <div className="space-y-2 text-sm">
                  <p><span className="text-gray-500">Banco:</span> {bankDetails.bank}</p>
                  <p><span className="text-gray-500">Tipo de Cuenta:</span> {bankDetails.accountType}</p>
                  <p><span className="text-gray-500">N° de Cuenta:</span> {bankDetails.accountNumber}</p>
                  <p><span className="text-gray-500">RUT:</span> {bankDetails.rut}</p>
                  <p><span className="text-gray-500">Nombre:</span> {bankDetails.name}</p>
                  <p><span className="text-gray-500">Email:</span> {bankDetails.email}</p>
                </div>
              </div>
              <div className="text-sm text-gray-500">
                <p>Una vez realizada la transferencia, envía el comprobante al email indicado.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}