import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { 
  LayoutDashboard, 
  MessageSquare, 
  ShoppingBag, 
  Users, 
  Calendar, 
  Megaphone, 
  ClipboardList, 
  FileText, 
  CreditCard,
  ChevronRight,
  Settings,
  Menu,
  X,
  Globe,
  LogOut,
  ChevronLeft
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { logout } from '../store/authSlice';
import { cn } from '../lib/utils';

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const menuItems = [
    { icon: LayoutDashboard, label: t('nav.dashboard'), href: '/dashboard' },
    { icon: MessageSquare, label: t('nav.whatsapp'), href: '/whatsapp' },
    { icon: ShoppingBag, label: t('nav.orders'), href: '/orders' },
    { icon: Users, label: t('nav.crm'), href: '/crm' },
    { icon: Calendar, label: t('nav.appointments'), href: '/appointments' },
    { icon: Megaphone, label: t('nav.marketing'), href: '/marketing' },
    { icon: ClipboardList, label: t('nav.surveys'), href: '/surveys' },
    { icon: FileText, label: t('nav.quotes'), href: '/quotes' },
    { icon: CreditCard, label: t('nav.subscription'), href: '/subscription' },
    { icon: Settings, label: t('nav.settings'), href: '/settings' }
  ];

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      {/* Mobile Header */}
      <div className="lg:hidden fixed top-0 left-0 right-0 bg-white z-30 border-b">
        <div className="flex items-center justify-between p-4">
          <button onClick={() => setIsMobileOpen(!isMobileOpen)} className="text-gray-600">
            <Menu className="w-6 h-6" />
          </button>
          <Link to="/dashboard" className="flex items-center gap-2">
            <Globe className="w-8 h-8 text-indigo-600" />
            <h1 className="text-xl font-bold text-gray-900">FlowUp</h1>
          </Link>
          <button onClick={handleLogout} className="text-gray-600">
            <LogOut className="w-6 h-6" />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div 
        className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 lg:hidden ${
          isMobileOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        <div 
          className={`fixed inset-y-0 left-0 w-64 bg-indigo-900 transform transition-transform duration-300 ease-in-out ${
            isMobileOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <div className="p-4">
            <div className="flex items-center justify-between mb-8">
              <Link to="/dashboard" className="flex items-center gap-2" onClick={() => setIsMobileOpen(false)}>
                <Globe className="w-8 h-8 text-white" />
                <h1 className="text-2xl font-bold text-white">FlowUp</h1>
              </Link>
              <button onClick={() => setIsMobileOpen(false)} className="text-white">
                <X className="w-6 h-6" />
              </button>
            </div>
            <nav>
              <ul className="space-y-2">
                {menuItems.map((item) => {
                  const isActive = location.pathname === item.href;
                  return (
                    <li key={item.label}>
                      <Link
                        to={item.href}
                        onClick={() => setIsMobileOpen(false)}
                        className={`flex items-center gap-3 px-4 py-3 rounded-lg transition-colors group text-white ${
                          isActive ? 'bg-indigo-800' : 'hover:bg-indigo-800'
                        }`}
                      >
                        <item.icon className="w-5 h-5" />
                        <span>{item.label}</span>
                        <ChevronRight className={`w-4 h-4 ml-auto ${
                          isActive ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
                        } transition-opacity`} />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <button
                onClick={handleLogout}
                className="flex items-center gap-2 px-4 py-3 w-full text-white hover:bg-indigo-800 rounded-lg transition-colors"
              >
                <LogOut className="w-5 h-5" />
                <span>Cerrar Sesión</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Sidebar */}
      <div 
        className={cn(
          'hidden lg:block fixed left-0 top-0 h-screen bg-indigo-900 text-white transition-all duration-300',
          isCollapsed ? 'w-20' : 'w-64'
        )}
      >
        <div className="flex items-center justify-between p-4">
          <Link to="/dashboard" className="flex items-center gap-2">
            <Globe className="w-8 h-8 flex-shrink-0" />
            {!isCollapsed && <h1 className="text-2xl font-bold">FlowUp</h1>}
          </Link>
          <button 
            onClick={toggleSidebar}
            className="text-white p-1 hover:bg-indigo-800 rounded-lg transition-colors"
          >
            <ChevronLeft className={cn(
              "w-5 h-5 transition-transform duration-300",
              isCollapsed && "rotate-180"
            )} />
          </button>
        </div>
        
        <nav className="p-4">
          <ul className="space-y-2">
            {menuItems.map((item) => {
              const isActive = location.pathname === item.href;
              return (
                <li key={item.label}>
                  <Link
                    to={item.href}
                    className={`flex items-center gap-3 px-4 py-3 rounded-lg transition-colors group ${
                      isActive ? 'bg-indigo-800' : 'hover:bg-indigo-800'
                    }`}
                    title={isCollapsed ? item.label : undefined}
                  >
                    <item.icon className="w-5 h-5 flex-shrink-0" />
                    {!isCollapsed && (
                      <>
                        <span>{item.label}</span>
                        <ChevronRight className={`w-4 h-4 ml-auto ${
                          isActive ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
                        } transition-opacity`} />
                      </>
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className={cn(
          "absolute bottom-0 left-0 right-0 p-4",
          isCollapsed && "p-2"
        )}>
          <button
            onClick={handleLogout}
            className={cn(
              "flex items-center gap-2 px-4 py-3 w-full text-white hover:bg-indigo-800 rounded-lg transition-colors",
              isCollapsed && "justify-center px-2"
            )}
            title={isCollapsed ? "Cerrar Sesión" : undefined}
          >
            <LogOut className="w-5 h-5" />
            {!isCollapsed && <span>Cerrar Sesión</span>}
          </button>
        </div>
      </div>

      {/* Main content margin */}
      <div className={cn(
        "transition-all duration-300",
        isCollapsed ? "lg:ml-20" : "lg:ml-64",
        "lg:mt-0 mt-16" // Margen superior solo en móvil
      )}>
        {/* El contenido principal irá aquí */}
      </div>
    </>
  );
}