import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { signIn } from '../lib/supabase';
import toast from 'react-hot-toast';

interface AuthState {
  isAuthenticated: boolean;
  type: 'admin' | 'user' | null;
  username: string | null;
  userId: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  type: null,
  username: null,
  userId: null,
  loading: false,
  error: null,
};

interface LoginCredentials {
  email: string;
  password: string;
}

export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials: LoginCredentials, { rejectWithValue }) => {
    try {
      const { auth, user, session } = await signIn(credentials.email, credentials.password);
      
      if (!session || !user) {
        throw new Error('Error en la autenticación');
      }

      // Store the session in localStorage
      localStorage.setItem('supabase.auth.token', session.access_token);
      
      return {
        username: user.full_name,
        type: user.role as 'admin' | 'user',
        userId: user.id
      };
    } catch (error) {
      console.error('Login error:', error);
      return rejectWithValue(error instanceof Error ? error.message : 'Error al iniciar sesión');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.type = null;
      state.username = null;
      state.userId = null;
      state.error = null;
      localStorage.removeItem('supabase.auth.token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.username = action.payload.username;
        state.type = action.payload.type;
        state.userId = action.payload.userId;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.username = null;
        state.type = null;
        state.userId = null;
        state.error = action.payload as string;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;