import React, { useState } from 'react';
import { X, Plus, HelpCircle, Mail, MessageSquare } from 'lucide-react';
import { useForm } from 'react-hook-form';

interface CreateQuoteModalProps {
  onClose: () => void;
}

interface QuoteItem {
  id: string;
  description: string;
  quantity: number;
  unitPrice: number;
}

interface QuoteFormData {
  client: string;
  items: QuoteItem[];
  expirationDate: string;
  notes: string;
  tax: number;
}

export default function CreateQuoteModal({ onClose }: CreateQuoteModalProps) {
  const { register, handleSubmit, watch } = useForm<QuoteFormData>();
  const [items, setItems] = useState<QuoteItem[]>([
    { id: '1', description: '', quantity: 1, unitPrice: 0 },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addItem = () => {
    setItems([
      ...items,
      {
        id: Date.now().toString(),
        description: '',
        quantity: 1,
        unitPrice: 0,
      },
    ]);
  };

  const removeItem = (id: string) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const calculateSubtotal = () => {
    return items.reduce((sum, item) => sum + item.quantity * item.unitPrice, 0);
  };

  const onSubmit = async (data: QuoteFormData) => {
    try {
      setIsSubmitting(true);
      // Aquí iría la lógica para crear la cotización
      console.log('Cotización creada:', { ...data, items });
      onClose();
    } catch (error) {
      console.error('Error al crear la cotización:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900">Crear Nueva Cotización</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Cliente
              </label>
              <select
                {...register('client', { required: true })}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              >
                <option value="">Seleccionar cliente</option>
                <option value="1">Juan Pérez</option>
                <option value="2">María González</option>
              </select>
            </div>

            <div>
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-sm font-medium text-gray-700">
                  Productos o Servicios
                </h3>
                <button
                  type="button"
                  onClick={addItem}
                  className="btn btn-secondary text-sm"
                >
                  <Plus className="w-4 h-4" /> Agregar Item
                </button>
              </div>

              <div className="space-y-4">
                {items.map((item, index) => (
                  <div
                    key={item.id}
                    className="grid grid-cols-12 gap-4 items-start border rounded-lg p-4"
                  >
                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Descripción
                      </label>
                      <input
                        type="text"
                        value={item.description}
                        onChange={(e) =>
                          setItems(
                            items.map((i) =>
                              i.id === item.id
                                ? { ...i, description: e.target.value }
                                : i
                            )
                          )
                        }
                        className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Cantidad
                      </label>
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) =>
                          setItems(
                            items.map((i) =>
                              i.id === item.id
                                ? { ...i, quantity: Number(e.target.value) }
                                : i
                            )
                          )
                        }
                        className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="col-span-3">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Precio Unitario
                      </label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        value={item.unitPrice}
                        onChange={(e) =>
                          setItems(
                            items.map((i) =>
                              i.id === item.id
                                ? { ...i, unitPrice: Number(e.target.value) }
                                : i
                            )
                          )
                        }
                        className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="col-span-1 pt-7">
                      {items.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeItem(item.id)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <X className="w-5 h-5" />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Fecha de Expiración
                </label>
                <input
                  type="date"
                  {...register('expirationDate', { required: true })}
                  className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Impuesto (%)
                </label>
                <input
                  type="number"
                  min="0"
                  max="100"
                  {...register('tax')}
                  className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Notas Adicionales
              </label>
              <textarea
                {...register('notes')}
                rows={3}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div className="border-t pt-4">
              <div className="flex justify-between text-sm mb-2">
                <span>Subtotal:</span>
                <span>${calculateSubtotal().toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-sm mb-2">
                <span>Impuesto:</span>
                <span>
                  ${((calculateSubtotal() * Number(watch('tax') || 0)) / 100).toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between font-bold">
                <span>Total:</span>
                <span>
                  $
                  {(
                    calculateSubtotal() +
                    (calculateSubtotal() * Number(watch('tax') || 0)) / 100
                  ).toFixed(2)}
                </span>
              </div>
            </div>

            <div className="flex justify-between items-center pt-4 border-t">
              <div className="flex gap-2">
                <button type="button" className="btn btn-secondary flex items-center gap-2">
                  <Mail className="w-4 h-4" />
                  Enviar por Email
                </button>
                <button type="button" className="btn btn-secondary flex items-center gap-2">
                  <MessageSquare className="w-4 h-4" />
                  Enviar por WhatsApp
                </button>
              </div>
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="btn btn-secondary"
                  disabled={isSubmitting}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Creando...' : 'Crear Cotización'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}