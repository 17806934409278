import React from 'react';
import { Calendar, Users, TrendingUp } from 'lucide-react';

const campaigns = [
  {
    id: 1,
    name: 'Campaña Ofertas de Verano',
    status: 'active',
    audience: '1,234',
    startDate: '2024-03-14',
    performance: '+15%',
  },
  {
    id: 2,
    name: 'Promoción Fin de Mes',
    status: 'paused',
    audience: '856',
    startDate: '2024-03-10',
    performance: '+8%',
  },
  {
    id: 3,
    name: 'Nuevos Productos',
    status: 'scheduled',
    audience: '2,100',
    startDate: '2024-03-20',
    performance: 'N/A',
  },
];

export default function RecentCampaigns() {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-lg font-bold text-gray-900 mb-6">Campañas Recientes</h2>
      <div className="space-y-4">
        {campaigns.map((campaign) => (
          <div
            key={campaign.id}
            className="p-4 border rounded-lg hover:bg-gray-50 transition-colors cursor-pointer"
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-medium text-gray-900">{campaign.name}</h3>
                <div className="flex items-center gap-4 mt-2 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <Calendar className="w-4 h-4" />
                    <span>{campaign.startDate}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Users className="w-4 h-4" />
                    <span>{campaign.audience}</span>
                  </div>
                  {campaign.performance !== 'N/A' && (
                    <div className="flex items-center gap-1 text-green-600">
                      <TrendingUp className="w-4 h-4" />
                      <span>{campaign.performance}</span>
                    </div>
                  )}
                </div>
              </div>
              <span
                className={`px-2 py-1 rounded-full text-xs ${
                  campaign.status === 'active'
                    ? 'bg-green-100 text-green-800'
                    : campaign.status === 'paused'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-blue-100 text-blue-800'
                }`}
              >
                {campaign.status === 'active'
                  ? 'Activa'
                  : campaign.status === 'paused'
                  ? 'Pausada'
                  : 'Programada'}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}