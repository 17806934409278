import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Plus, Save, Eye, Copy, Trash2 } from 'lucide-react';
import { MenuItem, MenuTemplate } from './types';
import { MenuNode } from './MenuNode';
import { MenuPreview } from './MenuPreview';
import { v4 as uuidv4 } from 'uuid';

interface MenuEditorProps {
  template: MenuTemplate;
  onSave: (template: MenuTemplate) => void;
}

export function MenuEditor({ template, onSave }: MenuEditorProps) {
  const [items, setItems] = useState<MenuItem[]>(template.items);
  const [showPreview, setShowPreview] = useState(false);
  const [editingItem, setEditingItem] = useState<MenuItem | null>(null);

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragItem);
    setItems(newItems);
  };

  const addNewItem = (type: MenuItem['type']) => {
    const newItem: MenuItem = {
      id: uuidv4(),
      title: 'Nuevo elemento',
      type,
      children: [],
    };
    setItems([...items, newItem]);
    setEditingItem(newItem);
  };

  const handleEdit = (item: MenuItem) => {
    setEditingItem(item);
  };

  const handleDelete = (item: MenuItem) => {
    const newItems = items.filter((i) => i.id !== item.id);
    setItems(newItems);
  };

  const handleSave = () => {
    onSave({
      ...template,
      items,
    });
  };

  const duplicateTemplate = () => {
    const newTemplate: MenuTemplate = {
      ...template,
      id: uuidv4(),
      name: `${template.name} (copia)`,
      isActive: false,
    };
    onSave(newTemplate);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">{template.name}</h2>
            <p className="text-sm text-gray-500">{template.description}</p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowPreview(!showPreview)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Eye className="w-4 h-4 mr-2" />
              {showPreview ? 'Ocultar vista previa' : 'Vista previa'}
            </button>
            <button
              onClick={duplicateTemplate}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Copy className="w-4 h-4 mr-2" />
              Duplicar
            </button>
            <button
              onClick={handleSave}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              <Save className="w-4 h-4 mr-2" />
              Guardar
            </button>
          </div>
        </div>

        <div className="flex gap-6 h-full">
          <div className="flex-1">
            <div className="bg-white rounded-lg shadow p-4 mb-4">
              <div className="flex gap-2 mb-4">
                <button
                  onClick={() => addNewItem('text')}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Texto
                </button>
                <button
                  onClick={() => addNewItem('image')}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Imagen
                </button>
                <button
                  onClick={() => addNewItem('link')}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Enlace
                </button>
                <button
                  onClick={() => addNewItem('button')}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Botón
                </button>
              </div>

              <div className="space-y-2">
                {items.map((item, index) => (
                  <MenuNode
                    key={item.id}
                    item={item}
                    index={index}
                    depth={0}
                    moveItem={moveItem}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                ))}
              </div>
            </div>
          </div>

          {showPreview && (
            <div className="w-80 bg-gray-100 rounded-lg p-4">
              <MenuPreview items={items} />
            </div>
          )}
        </div>

        {editingItem && (
          <MenuItemEditor
            item={editingItem}
            onSave={(updatedItem) => {
              const newItems = items.map((i) =>
                i.id === updatedItem.id ? updatedItem : i
              );
              setItems(newItems);
              setEditingItem(null);
            }}
            onCancel={() => setEditingItem(null)}
          />
        )}
      </div>
    </DndProvider>
  );
}
