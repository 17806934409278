import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, HelpCircle } from 'lucide-react';
import SurveyStats from '../components/surveys/SurveyStats';
import RecentSurveys from '../components/surveys/RecentSurveys';
import CreateSurveyModal from '../components/surveys/CreateSurveyModal';
import SurveyAnalytics from '../components/surveys/SurveyAnalytics';
import SurveyTemplates from '../components/surveys/SurveyTemplates';

export default function Surveys() {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);

  return (
    <>
      <header className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">{t('surveys.title')}</h1>
            <p className="text-gray-500 mt-1">{t('surveys.subtitle')}</p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowTemplates(true)}
              className="btn btn-secondary flex items-center gap-2"
            >
              <HelpCircle className="w-4 h-4" />
              Plantillas
            </button>
            <button
              onClick={() => setShowCreateModal(true)}
              className="btn btn-primary flex items-center gap-2"
            >
              <Plus className="w-4 h-4" />
              Nueva Encuesta
            </button>
          </div>
        </div>
      </header>

      <SurveyStats />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <RecentSurveys />
        <SurveyAnalytics />
      </div>

      {showCreateModal && (
        <CreateSurveyModal onClose={() => setShowCreateModal(false)} />
      )}

      {showTemplates && (
        <SurveyTemplates onClose={() => setShowTemplates(false)} />
      )}
    </>
  );
}