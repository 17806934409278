import React, { useState } from 'react';
import { GripVertical, Plus, Trash2, ChevronRight } from 'lucide-react';

interface MenuItem {
  id: string;
  title: string;
  action: string;
  response: string;
}

export default function MenuBuilder() {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    {
      id: '1',
      title: 'Products',
      action: 'message',
      response: 'Here are our latest products...',
    },
    {
      id: '2',
      title: 'Book Appointment',
      action: 'link',
      response: 'https://example.com/book',
    },
  ]);

  const addMenuItem = () => {
    setMenuItems([
      ...menuItems,
      {
        id: Date.now().toString(),
        title: '',
        action: 'message',
        response: '',
      },
    ]);
  };

  const removeMenuItem = (id: string) => {
    setMenuItems(menuItems.filter((item) => item.id !== id));
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Menu Builder</h2>
        <button
          onClick={addMenuItem}
          className="btn btn-primary flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Add Menu Item
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="space-y-4">
          {menuItems.map((item, index) => (
            <div
              key={item.id}
              className="border rounded-lg p-4 bg-white"
            >
              <div className="flex items-center gap-3 mb-4">
                <GripVertical className="w-5 h-5 text-gray-400 cursor-move" />
                <span className="text-sm font-medium text-gray-500">
                  Option {index + 1}
                </span>
                <button
                  onClick={() => removeMenuItem(item.id)}
                  className="ml-auto text-red-600 hover:text-red-700"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Title
                  </label>
                  <input
                    type="text"
                    value={item.title}
                    onChange={(e) =>
                      setMenuItems(
                        menuItems.map((m) =>
                          m.id === item.id
                            ? { ...m, title: e.target.value }
                            : m
                        )
                      )
                    }
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Action Type
                  </label>
                  <select
                    value={item.action}
                    onChange={(e) =>
                      setMenuItems(
                        menuItems.map((m) =>
                          m.id === item.id
                            ? { ...m, action: e.target.value }
                            : m
                        )
                      )
                    }
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option value="message">Send Message</option>
                    <option value="link">Open Link</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    {item.action === 'message' ? 'Response Message' : 'URL'}
                  </label>
                  {item.action === 'message' ? (
                    <textarea
                      value={item.response}
                      onChange={(e) =>
                        setMenuItems(
                          menuItems.map((m) =>
                            m.id === item.id
                              ? { ...m, response: e.target.value }
                              : m
                          )
                        )
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      rows={3}
                    />
                  ) : (
                    <input
                      type="url"
                      value={item.response}
                      onChange={(e) =>
                        setMenuItems(
                          menuItems.map((m) =>
                            m.id === item.id
                              ? { ...m, response: e.target.value }
                              : m
                          )
                        )
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-gray-50 p-6 rounded-lg">
          <h3 className="text-sm font-medium text-gray-700 mb-4">Preview</h3>
          <div className="bg-white rounded-lg p-4 shadow-sm space-y-2">
            {menuItems.map((item, index) => (
              <div
                key={item.id}
                className="flex items-center justify-between p-2 hover:bg-gray-50 rounded cursor-pointer"
              >
                <span className="text-sm">
                  {index + 1}. {item.title || 'Untitled Option'}
                </span>
                <ChevronRight className="w-4 h-4 text-gray-400" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}