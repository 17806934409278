import React, { useState } from 'react';
import { X, MapPin, Phone, Mail, Truck, Clock } from 'lucide-react';
import { OrderStatus } from '../../types/orders';

interface OrderDetailsProps {
  orderId: string;
  onClose: () => void;
}

export default function OrderDetails({ orderId, onClose }: OrderDetailsProps) {
  const [currentStatus, setCurrentStatus] = useState(OrderStatus.PROCESSING);

  const handleStatusChange = (newStatus: OrderStatus) => {
    setCurrentStatus(newStatus);
    // Aquí se implementaría la lógica para actualizar el estado y enviar notificaciones
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900">
              Detalles del Pedido {orderId}
            </h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="font-medium text-gray-900 mb-4">Información del Cliente</h3>
              <div className="space-y-3">
                <div className="flex items-center gap-2">
                  <div className="text-gray-500">
                    <MapPin className="w-5 h-5" />
                  </div>
                  <div>
                    <p className="font-medium">Juan Pérez</p>
                    <p className="text-sm text-gray-500">Av. Principal 123, Santiago</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Phone className="w-5 h-5 text-gray-500" />
                  <span>+56 9 1234 5678</span>
                </div>
                <div className="flex items-center gap-2">
                  <Mail className="w-5 h-5 text-gray-500" />
                  <span>juan.perez@email.com</span>
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-medium text-gray-900 mb-4">Estado del Pedido</h3>
              <select
                value={currentStatus}
                onChange={(e) => handleStatusChange(e.target.value as OrderStatus)}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              >
                {Object.values(OrderStatus).map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>

              <div className="mt-4">
                <h4 className="font-medium text-gray-900 mb-2">Historial de Estados</h4>
                <div className="space-y-3">
                  <div className="flex items-start gap-2">
                    <Clock className="w-5 h-5 text-gray-500" />
                    <div>
                      <p className="text-sm font-medium">Pedido Recibido</p>
                      <p className="text-xs text-gray-500">14 Mar 2024, 10:30</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <Truck className="w-5 h-5 text-gray-500" />
                    <div>
                      <p className="text-sm font-medium">En Proceso</p>
                      <p className="text-xs text-gray-500">14 Mar 2024, 11:45</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="font-medium text-gray-900 mb-4">Detalles del Pedido</h3>
            <table className="w-full">
              <thead>
                <tr className="text-left border-b">
                  <th className="pb-2">Producto</th>
                  <th className="pb-2">Cantidad</th>
                  <th className="pb-2">Precio</th>
                  <th className="pb-2">Total</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                <tr>
                  <td className="py-2">Producto 1</td>
                  <td className="py-2">2</td>
                  <td className="py-2">$45.000</td>
                  <td className="py-2">$90.000</td>
                </tr>
                <tr>
                  <td className="py-2">Producto 2</td>
                  <td className="py-2">1</td>
                  <td className="py-2">$66.000</td>
                  <td className="py-2">$66.000</td>
                </tr>
              </tbody>
              <tfoot className="border-t">
                <tr>
                  <td colSpan={3} className="py-2 text-right font-medium">Total:</td>
                  <td className="py-2 font-bold">$156.000</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="mt-6">
            <h3 className="font-medium text-gray-900 mb-2">Notas del Cliente</h3>
            <p className="text-gray-600 text-sm">
              Por favor entregar en horario de oficina.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}