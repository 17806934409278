import React from 'react';
import { Download, FileText } from 'lucide-react';

const paymentHistory = [
  {
    id: '1',
    date: '2024-03-14',
    amount: 49900,
    status: 'completed',
    invoice: 'FAC-001',
    plan: 'PYME'
  },
  {
    id: '2',
    date: '2024-02-14',
    amount: 49900,
    status: 'completed',
    invoice: 'FAC-002',
    plan: 'PYME'
  },
  {
    id: '3',
    date: '2024-01-14',
    amount: 29900,
    status: 'completed',
    invoice: 'FAC-003',
    plan: 'Emprendedor'
  }
];

export default function PaymentHistory() {
  const handleDownloadInvoice = (invoiceId: string) => {
    // Aquí iría la lógica para descargar la factura
    console.log('Descargando factura:', invoiceId);
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-bold text-gray-900 mb-6">Historial de Pagos</h2>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b">
              <th className="pb-3 font-medium">Fecha</th>
              <th className="pb-3 font-medium">Plan</th>
              <th className="pb-3 font-medium">Monto</th>
              <th className="pb-3 font-medium">Estado</th>
              <th className="pb-3 font-medium">Factura</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {paymentHistory.map((payment) => (
              <tr key={payment.id} className="hover:bg-gray-50">
                <td className="py-4">{payment.date}</td>
                <td className="py-4">{payment.plan}</td>
                <td className="py-4">${payment.amount.toLocaleString()}</td>
                <td className="py-4">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Completado
                  </span>
                </td>
                <td className="py-4">
                  <button
                    onClick={() => handleDownloadInvoice(payment.invoice)}
                    className="flex items-center gap-1 text-indigo-600 hover:text-indigo-700"
                  >
                    <Download className="w-4 h-4" />
                    <span className="text-sm">Descargar</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}