import React, { useState, useEffect } from 'react';
import { CheckCircle, AlertCircle, Loader } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { saveWhatsAppConfig, getWhatsAppConfig, type WhatsAppConfig } from '../../lib/whatsapp';
import toast from 'react-hot-toast';

interface WhatsAppSetupProps {
  apiUrl: string;
  connectionStatus: string;
}

export default function WhatsAppSetup({ apiUrl, connectionStatus }: WhatsAppSetupProps) {
  const { user } = useAuth();
  const [formData, setFormData] = useState<WhatsAppConfig>({
    phone: '',
    token: '',
    permissions: {
      sendMessages: true,
      receiveMessages: true,
      readStatus: false,
      manageTemplates: false,
    },
    botConfig: {
      enabled: false,
      gptModel: 'gpt-4',
      apiKey: '',
      systemPrompt: 'You are a helpful assistant that helps customers with their inquiries, orders, and appointments.',
      dataCollection: {
        enabled: false,
        spreadsheetId: '',
        sheetName: 'WhatsApp Data',
        fields: []
      },
      calendar: {
        enabled: false,
        calendarId: '',
        reminderSettings: {
          appointments: true,
          payments: true,
          followUps: true
        }
      }
    }
  });
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [qrCode, setQrCode] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      loadConfig();
    }
  }, [user]);

  useEffect(() => {
    if (connectionStatus === 'qr_ready') {
      fetchQR();
    } else {
      setQrCode(null);
    }
  }, [connectionStatus]);

  const loadConfig = async () => {
    if (!user) return;
    
    try {
      const config = await getWhatsAppConfig(user.id);
      if (config) {
        setFormData(config);
      }
    } catch (error) {
      console.error('Error loading WhatsApp config:', error);
      toast.error('Error al cargar la configuración de WhatsApp');
    }
  };

  const fetchQR = async () => {
    try {
      const response = await fetch(`${apiUrl}/qr`);
      const data = await response.json();
      if (data.qr) {
        setQrCode(data.qr);
      }
    } catch (error) {
      console.error('Error fetching QR:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setStatus('loading');
    try {
      const result = await saveWhatsAppConfig(user.id, formData);
      if (result.success) {
        setStatus('success');
        toast.success('Configuración guardada correctamente');
      } else {
        throw new Error('Error al guardar la configuración');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
      toast.error('Error al guardar la configuración');
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h2 className="text-xl font-semibold mb-4">WhatsApp Connection Setup</h2>
        
        {connectionStatus === 'connected' ? (
          <div className="bg-green-50 p-4 rounded-lg">
            <p className="text-green-800 font-medium">
              WhatsApp is connected and ready to use!
            </p>
            <p className="text-green-600 mt-2">
              You can now use the Chat tab to send and receive messages.
            </p>
          </div>
        ) : connectionStatus === 'qr_ready' && qrCode ? (
          <div className="text-center">
            <p className="mb-4 text-gray-600">
              Scan this QR code with WhatsApp on your phone to connect
            </p>
            <div className="inline-block p-4 bg-white rounded-lg shadow-md">
              <img src={qrCode} alt="WhatsApp QR Code" className="w-64 h-64" />
            </div>
            <p className="mt-4 text-sm text-gray-500">
              To log in, open WhatsApp on your phone, tap Menu or Settings and select WhatsApp Web
            </p>
          </div>
        ) : (
          <div className="bg-yellow-50 p-4 rounded-lg">
            <p className="text-yellow-800">
              Initializing WhatsApp connection... Please wait.
            </p>
          </div>
        )}

        <div className="mt-6">
          <h3 className="font-medium mb-2">Connection Status</h3>
          <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm ${
            connectionStatus === 'connected' 
              ? 'bg-green-100 text-green-800' 
              : connectionStatus === 'qr_ready'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-red-100 text-red-800'
          }`}>
            {connectionStatus}
          </div>
        </div>

        {/* WhatsApp Business Configuration */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">WhatsApp Business Configuration</h3>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Número de WhatsApp Business
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="token" className="block text-sm font-medium text-gray-700">
                API Token
              </label>
              <input
                type="password"
                id="token"
                name="token"
                value={formData.token}
                onChange={(e) => setFormData({ ...formData, token: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        {/* ChatGPT Bot Configuration */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">ChatGPT Bot Configuration</h3>
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="botEnabled"
                checked={formData.botConfig?.enabled}
                onChange={(e) => setFormData({
                  ...formData,
                  botConfig: {
                    ...formData.botConfig!,
                    enabled: e.target.checked
                  }
                })}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label htmlFor="botEnabled" className="ml-2 block text-sm text-gray-900">
                Enable ChatGPT Bot
              </label>
            </div>

            {formData.botConfig?.enabled && (
              <>
                <div>
                  <label htmlFor="gptApiKey" className="block text-sm font-medium text-gray-700">
                    OpenAI API Key
                  </label>
                  <input
                    type="password"
                    id="gptApiKey"
                    value={formData.botConfig.apiKey}
                    onChange={(e) => setFormData({
                      ...formData,
                      botConfig: {
                        ...formData.botConfig!,
                        apiKey: e.target.value
                      }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="systemPrompt" className="block text-sm font-medium text-gray-700">
                    System Prompt
                  </label>
                  <textarea
                    id="systemPrompt"
                    value={formData.botConfig.systemPrompt}
                    onChange={(e) => setFormData({
                      ...formData,
                      botConfig: {
                        ...formData.botConfig!,
                        systemPrompt: e.target.value
                      }
                    })}
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Google Sheets Integration */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Data Collection (Google Sheets)</h3>
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="sheetsEnabled"
                checked={formData.botConfig?.dataCollection.enabled}
                onChange={(e) => setFormData({
                  ...formData,
                  botConfig: {
                    ...formData.botConfig!,
                    dataCollection: {
                      ...formData.botConfig!.dataCollection,
                      enabled: e.target.checked
                    }
                  }
                })}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label htmlFor="sheetsEnabled" className="ml-2 block text-sm text-gray-900">
                Enable Data Collection
              </label>
            </div>

            {formData.botConfig?.dataCollection.enabled && (
              <>
                <div>
                  <label htmlFor="spreadsheetId" className="block text-sm font-medium text-gray-700">
                    Google Spreadsheet ID
                  </label>
                  <input
                    type="text"
                    id="spreadsheetId"
                    value={formData.botConfig.dataCollection.spreadsheetId}
                    onChange={(e) => setFormData({
                      ...formData,
                      botConfig: {
                        ...formData.botConfig!,
                        dataCollection: {
                          ...formData.botConfig!.dataCollection,
                          spreadsheetId: e.target.value
                        }
                      }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="sheetName" className="block text-sm font-medium text-gray-700">
                    Sheet Name
                  </label>
                  <input
                    type="text"
                    id="sheetName"
                    value={formData.botConfig.dataCollection.sheetName}
                    onChange={(e) => setFormData({
                      ...formData,
                      botConfig: {
                        ...formData.botConfig!,
                        dataCollection: {
                          ...formData.botConfig!.dataCollection,
                          sheetName: e.target.value
                        }
                      }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Google Calendar Integration */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Calendar Integration</h3>
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="calendarEnabled"
                checked={formData.botConfig?.calendar.enabled}
                onChange={(e) => setFormData({
                  ...formData,
                  botConfig: {
                    ...formData.botConfig!,
                    calendar: {
                      ...formData.botConfig!.calendar,
                      enabled: e.target.checked
                    }
                  }
                })}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label htmlFor="calendarEnabled" className="ml-2 block text-sm text-gray-900">
                Enable Calendar Integration
              </label>
            </div>

            {formData.botConfig?.calendar.enabled && (
              <>
                <div>
                  <label htmlFor="calendarId" className="block text-sm font-medium text-gray-700">
                    Google Calendar ID
                  </label>
                  <input
                    type="text"
                    id="calendarId"
                    value={formData.botConfig.calendar.calendarId}
                    onChange={(e) => setFormData({
                      ...formData,
                      botConfig: {
                        ...formData.botConfig!,
                        calendar: {
                          ...formData.botConfig!.calendar,
                          calendarId: e.target.value
                        }
                      }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Reminder Settings
                  </label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="appointmentReminders"
                        checked={formData.botConfig.calendar.reminderSettings.appointments}
                        onChange={(e) => setFormData({
                          ...formData,
                          botConfig: {
                            ...formData.botConfig!,
                            calendar: {
                              ...formData.botConfig!.calendar,
                              reminderSettings: {
                                ...formData.botConfig!.calendar.reminderSettings,
                                appointments: e.target.checked
                              }
                            }
                          }
                        })}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label htmlFor="appointmentReminders" className="ml-2 text-sm text-gray-700">
                        Appointment Reminders
                      </label>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="paymentReminders"
                        checked={formData.botConfig.calendar.reminderSettings.payments}
                        onChange={(e) => setFormData({
                          ...formData,
                          botConfig: {
                            ...formData.botConfig!,
                            calendar: {
                              ...formData.botConfig!.calendar,
                              reminderSettings: {
                                ...formData.botConfig!.calendar.reminderSettings,
                                payments: e.target.checked
                              }
                            }
                          }
                        })}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label htmlFor="paymentReminders" className="ml-2 text-sm text-gray-700">
                        Payment Reminders
                      </label>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="followUpReminders"
                        checked={formData.botConfig.calendar.reminderSettings.followUps}
                        onChange={(e) => setFormData({
                          ...formData,
                          botConfig: {
                            ...formData.botConfig!,
                            calendar: {
                              ...formData.botConfig!.calendar,
                              reminderSettings: {
                                ...formData.botConfig!.calendar.reminderSettings,
                                followUps: e.target.checked
                              }
                            }
                          }
                        })}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label htmlFor="followUpReminders" className="ml-2 text-sm text-gray-700">
                        Follow-up Reminders
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={status === 'loading'}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
          >
            {status === 'loading' ? (
              <>
                <Loader className="animate-spin -ml-1 mr-2 h-4 w-4" />
                Saving...
              </>
            ) : (
              'Save Configuration'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}