import React, { useState, useEffect } from 'react';
import { Send } from 'lucide-react';

interface Chat {
  id: string;
  name: string;
  lastMessage?: string;
}

interface WhatsAppChatProps {
  apiUrl: string;
}

export default function WhatsAppChat({ apiUrl }: WhatsAppChatProps) {
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchChats();
  }, []);

  const fetchChats = async () => {
    try {
      const response = await fetch(`${apiUrl}/chats`);
      const data = await response.json();
      setChats(data.map((chat: any) => ({
        id: chat.id._serialized,
        name: chat.name || chat.id.user,
        lastMessage: chat.lastMessage?.body
      })));
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const sendMessage = async () => {
    if (!selectedChat || !message.trim()) return;

    setLoading(true);
    try {
      await fetch(`${apiUrl}/send-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          number: selectedChat,
          message: message.trim(),
        }),
      });
      setMessage('');
      await fetchChats(); // Refresh chats
    } catch (error) {
      console.error('Error sending message:', error);
    }
    setLoading(false);
  };

  return (
    <div className="flex h-[600px]">
      {/* Chat list */}
      <div className="w-1/3 border-r">
        <div className="p-4 border-b">
          <input
            type="text"
            placeholder="Search chats..."
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div className="overflow-y-auto h-[calc(100%-73px)]">
          {chats.map((chat) => (
            <div
              key={chat.id}
              onClick={() => setSelectedChat(chat.id)}
              className={`p-4 cursor-pointer hover:bg-gray-50 ${
                selectedChat === chat.id ? 'bg-indigo-50' : ''
              }`}
            >
              <div className="font-medium">{chat.name}</div>
              {chat.lastMessage && (
                <div className="text-sm text-gray-500 truncate">
                  {chat.lastMessage}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Chat area */}
      <div className="flex-1 flex flex-col">
        {selectedChat ? (
          <>
            <div className="p-4 border-b">
              <div className="font-medium">
                {chats.find(c => c.id === selectedChat)?.name}
              </div>
            </div>
            <div className="flex-1 p-4">
              {/* Messages will be displayed here */}
            </div>
            <div className="p-4 border-t">
              <div className="flex gap-2">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type a message..."
                  className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
                <button
                  onClick={sendMessage}
                  disabled={loading}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  <Send className="w-5 h-5" />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center text-gray-500">
            Select a chat to start messaging
          </div>
        )}
      </div>
    </div>
  );
}
