import React from 'react';
import { Clock } from 'lucide-react';

const appointments = [
  {
    id: 1,
    client: 'Sarah Johnson',
    time: '10:00 AM',
    service: 'Consultation',
    status: 'confirmed'
  },
  {
    id: 2,
    client: 'Mike Peters',
    time: '11:30 AM',
    service: 'Follow-up',
    status: 'pending'
  },
  {
    id: 3,
    client: 'Emma Wilson',
    time: '2:00 PM',
    service: 'Initial Meeting',
    status: 'confirmed'
  }
];

export default function UpcomingAppointments() {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-bold text-gray-900 mb-6">Upcoming Appointments</h2>
      <div className="space-y-4">
        {appointments.map((appointment) => (
          <div
            key={appointment.id}
            className="p-4 border rounded-lg hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-start justify-between">
              <div>
                <h3 className="font-medium text-gray-900">{appointment.client}</h3>
                <p className="text-sm text-gray-500">{appointment.service}</p>
                <div className="flex items-center gap-1 mt-2 text-sm text-gray-500">
                  <Clock className="w-4 h-4" />
                  <span>{appointment.time}</span>
                </div>
              </div>
              <span
                className={`px-2 py-1 rounded-full text-xs ${
                  appointment.status === 'confirmed'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-yellow-100 text-yellow-800'
                }`}
              >
                {appointment.status}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}