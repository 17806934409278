import React from 'react';
import { ClipboardList, Users, CheckSquare, Star } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function SurveyStats() {
  const { t } = useTranslation();

  const stats = [
    {
      label: 'Encuestas Activas',
      value: '8',
      change: '+2',
      icon: ClipboardList,
      color: 'bg-blue-500',
    },
    {
      label: 'Total Respuestas',
      value: '1,234',
      change: '+156',
      icon: Users,
      color: 'bg-yellow-500',
    },
    {
      label: 'Tasa de Finalización',
      value: '78%',
      change: '+5%',
      icon: CheckSquare,
      color: 'bg-green-500',
    },
    {
      label: 'Calificación Promedio',
      value: '4.5',
      change: '+0.2',
      icon: Star,
      color: 'bg-purple-500',
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      {stats.map((stat) => (
        <div
          key={stat.label}
          className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow"
        >
          <div className="flex items-center justify-between mb-4">
            <div className={`${stat.color} p-3 rounded-lg`}>
              <stat.icon className="w-6 h-6 text-white" />
            </div>
            <span className="text-green-500 text-sm font-medium">{stat.change}</span>
          </div>
          <h3 className="text-gray-500 text-sm font-medium">{stat.label}</h3>
          <p className="text-2xl font-bold text-gray-900 mt-1">{stat.value}</p>
        </div>
      ))}
    </div>
  );
}