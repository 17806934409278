import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { supabase } from '../lib/supabase';
import { Link } from 'react-router-dom';
import { 
  MessageSquare, 
  Users, 
  Calendar, 
  FileText, 
  Settings,
  TrendingUp,
  Clock,
  Bell
} from 'lucide-react';

export default function Dashboard() {
  const { t } = useTranslation();
  const [businessName, setBusinessName] = useState('');
  const [businessNotConfigured, setBusinessNotConfigured] = useState(false);
  const [stats, setStats] = useState({
    newClients: 0,
    pendingAppointments: 0,
    unreadMessages: 0,
    pendingQuotes: 0
  });
  const [recentActivity, setRecentActivity] = useState({
    clients: [],
    appointments: [],
    messages: []
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          // Fetch business info
          const { data: userData, error } = await supabase
            .from('users')
            .select('business_name, company_name')
            .eq('id', user.id)
            .single();

          if (error) throw error;
          if (userData?.business_name || userData?.company_name) {
            setBusinessName(userData.business_name || userData.company_name);
          } else {
            setBusinessNotConfigured(true);
          }

          // Fetch dashboard stats
          const dashboardStats = await fetchDashboardStats(user.id);
          setStats(dashboardStats);

          // Fetch recent activity
          const activity = await fetchRecentActivity(user.id);
          setRecentActivity(activity);
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }

    fetchData();
  }, []);

  const quickActions = [
    {
      title: 'WhatsApp',
      description: 'Gestiona tus conversaciones',
      icon: MessageSquare,
      href: '/whatsapp',
      color: 'text-green-600',
      bgColor: 'bg-green-100'
    },
    {
      title: 'Clientes',
      description: 'Administra tus clientes',
      icon: Users,
      href: '/crm',
      color: 'text-blue-600',
      bgColor: 'bg-blue-100'
    },
    {
      title: 'Citas',
      description: 'Agenda y gestiona citas',
      icon: Calendar,
      href: '/appointments',
      color: 'text-purple-600',
      bgColor: 'bg-purple-100'
    },
    {
      title: 'Cotizaciones',
      description: 'Crea y envía cotizaciones',
      icon: FileText,
      href: '/quotes',
      color: 'text-yellow-600',
      bgColor: 'bg-yellow-100'
    }
  ];

  return (
    <div className="flex-1 overflow-auto">
      <div className="max-w-[1200px] mx-auto p-4 sm:p-6 lg:p-8">
        {/* Header Section */}
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">
                {businessName 
                  ? `¡Bienvenido de nuevo, ${businessName}!`
                  : '¡Bienvenido a tu Panel Principal!'}
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Aquí está lo que está pasando en tu negocio hoy.
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <button className="p-2 text-gray-400 hover:text-gray-500">
                <Bell className="h-6 w-6" />
              </button>
              <Link
                to="/business/config"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <Settings className="h-4 w-4 mr-2" />
                Configuración
              </Link>
            </div>
          </div>
        </div>

        {/* Business Setup Alert */}
        {businessNotConfigured && (
          <div className="mb-6 p-4 rounded-lg bg-yellow-50 border border-yellow-200">
            <div className="flex">
              <div className="flex-shrink-0">
                <Bell className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Configura tu negocio
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    Para aprovechar todas las funciones, completa la configuración de tu negocio.
                  </p>
                </div>
                <div className="mt-4">
                  <div className="-mx-2 -my-1.5 flex">
                    <Link
                      to="/business/config"
                      className="px-3 py-2 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                    >
                      Configurar ahora
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Quick Actions Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {quickActions.map((action) => (
            <Link
              key={action.title}
              to={action.href}
              className="relative group rounded-lg p-6 bg-white shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200"
            >
              <div className={`
                inline-flex p-3 rounded-lg
                ${action.bgColor}
              `}>
                <action.icon className={`
                  h-6 w-6
                  ${action.color}
                `} />
              </div>
              <h3 className="mt-4 text-base font-semibold text-gray-900 group-hover:text-indigo-600">
                {action.title}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {action.description}
              </p>
            </Link>
          ))}
        </div>

        {/* Stats Overview */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium text-gray-500">Clientes Nuevos</h3>
              <TrendingUp className="h-5 w-5 text-green-500" />
            </div>
            <p className="mt-2 text-3xl font-semibold text-gray-900">{stats.newClients}</p>
            <p className="mt-1 text-sm text-gray-500">Esta semana</p>
          </div>
          
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium text-gray-500">Citas Pendientes</h3>
              <Clock className="h-5 w-5 text-blue-500" />
            </div>
            <p className="mt-2 text-3xl font-semibold text-gray-900">{stats.pendingAppointments}</p>
            <p className="mt-1 text-sm text-gray-500">Para hoy</p>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium text-gray-500">Mensajes Nuevos</h3>
              <MessageSquare className="h-5 w-5 text-indigo-500" />
            </div>
            <p className="mt-2 text-3xl font-semibold text-gray-900">{stats.unreadMessages}</p>
            <p className="mt-1 text-sm text-gray-500">Sin responder</p>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium text-gray-500">Cotizaciones</h3>
              <FileText className="h-5 w-5 text-yellow-500" />
            </div>
            <p className="mt-2 text-3xl font-semibold text-gray-900">{stats.pendingQuotes}</p>
            <p className="mt-1 text-sm text-gray-500">Por aprobar</p>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div className="p-6">
            <h2 className="text-base font-semibold text-gray-900">Actividad Reciente</h2>
            <div className="mt-6 flow-root">
              <ul className="-my-5 divide-y divide-gray-200">
                {recentActivity.clients.map((client: any) => (
                  <li key={client.id} className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <Users className="h-6 w-6 text-gray-400" />
                      </div>
                      <div className="min-w-0 flex-1">
                        <p className="text-sm font-medium text-gray-900">
                          Nuevo cliente registrado
                        </p>
                        <p className="text-sm text-gray-500">
                          {client.name} se registró
                        </p>
                      </div>
                      <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                        {new Date(client.created_at).toLocaleDateString()}
                      </div>
                    </div>
                  </li>
                ))}
                {recentActivity.appointments.map((appointment: any) => (
                  <li key={appointment.id} className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <Calendar className="h-6 w-6 text-gray-400" />
                      </div>
                      <div className="min-w-0 flex-1">
                        <p className="text-sm font-medium text-gray-900">
                          Nueva cita programada
                        </p>
                        <p className="text-sm text-gray-500">
                          {appointment.client_name} agendó una cita
                        </p>
                      </div>
                      <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                        {new Date(appointment.appointment_date).toLocaleDateString()}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function fetchDashboardStats(userId: string) {
  try {
    const { data: stats, error } = await supabase
      .from('dashboard_stats')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (error) throw error;

    return {
      newClients: stats?.new_clients || 0,
      pendingAppointments: stats?.pending_appointments || 0,
      unreadMessages: stats?.unread_messages || 0,
      pendingQuotes: stats?.pending_quotes || 0
    };
  } catch (error) {
    console.error('Error fetching dashboard stats:', error);
    return {
      newClients: 0,
      pendingAppointments: 0,
      unreadMessages: 0,
      pendingQuotes: 0
    };
  }
}

async function fetchRecentActivity(userId: string) {
  try {
    const { data: activity, error } = await supabase
      .from('recent_activity')
      .select('*')
      .eq('user_id', userId)
      .limit(5);

    if (error) throw error;

    return {
      clients: activity?.filter(item => item.type === 'client') || [],
      appointments: activity?.filter(item => item.type === 'appointment') || [],
      messages: activity?.filter(item => item.type === 'message') || []
    };
  } catch (error) {
    console.error('Error fetching recent activity:', error);
    return {
      clients: [],
      appointments: [],
      messages: []
    };
  }
}