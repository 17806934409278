import { supabase } from './supabase';

// Función para simular la conexión de una red social
export async function connectSocialMedia(platform: string, userId: string): Promise<void> {
  try {
    // Simular un delay para dar sensación de proceso
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Guardar el estado de conexión en Supabase
    const { error } = await supabase
      .from('social_media_connections')
      .upsert({
        user_id: userId,
        platform,
        connected: true,
        connected_at: new Date().toISOString()
      });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Error al conectar red social:', error);
    throw error;
  }
}

// Función para desconectar una red social
export async function disconnectSocialMedia(platform: string, userId: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('social_media_connections')
      .delete()
      .match({ user_id: userId, platform });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Error al desconectar red social:', error);
    throw error;
  }
}

// Función para verificar el estado de conexión
export async function checkSocialMediaConnection(platform: string, userId: string): Promise<boolean> {
  try {
    const { data, error } = await supabase
      .from('social_media_connections')
      .select('connected')
      .match({ user_id: userId, platform })
      .single();

    if (error) {
      throw error;
    }

    return data?.connected || false;
  } catch (error) {
    console.error('Error al verificar conexión:', error);
    return false;
  }
}
