import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  CreditCard, 
  Calendar, 
  Download, 
  CheckCircle,
  Building2,
  FileText,
  Clock
} from 'lucide-react';
import { RootState } from '../store/store';
import PaymentMethodModal from '../components/subscription/PaymentMethodModal';
import PaymentHistory from '../components/subscription/PaymentHistory';

export default function Subscription() {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'card' | 'transfer' | null>(null);

  // Simulated current plan data
  const currentPlan = {
    name: 'PYME',
    price: 49900,
    billingCycle: 'monthly',
    nextBilling: '2024-04-14',
    status: 'active'
  };

  return (
    <>
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Suscripción</h1>
        <p className="text-gray-500 mt-1">Gestiona tu plan y pagos</p>
      </header>

      {/* Current Plan */}
      <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
        <div className="flex justify-between items-start">
          <div>
            <h2 className="text-xl font-bold text-gray-900">Plan Actual</h2>
            <div className="mt-4 space-y-2">
              <p className="flex items-center gap-2">
                <CreditCard className="w-5 h-5 text-gray-400" />
                <span className="font-medium">Plan {currentPlan.name}</span>
              </p>
              <p className="flex items-center gap-2">
                <Calendar className="w-5 h-5 text-gray-400" />
                <span>Próximo pago: {currentPlan.nextBilling}</span>
              </p>
              <p className="flex items-center gap-2">
                <Clock className="w-5 h-5 text-gray-400" />
                <span>Facturación {currentPlan.billingCycle === 'monthly' ? 'Mensual' : 'Anual'}</span>
              </p>
            </div>
          </div>
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            <CheckCircle className="w-4 h-4 mr-1" />
            Activo
          </span>
        </div>
      </div>

      {/* Payment Methods */}
      <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Métodos de Pago</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <button
            onClick={() => {
              setSelectedPaymentMethod('card');
              setShowPaymentModal(true);
            }}
            className="p-4 border rounded-lg hover:bg-gray-50 text-left flex items-center gap-3"
          >
            <CreditCard className="w-6 h-6 text-gray-400" />
            <div>
              <p className="font-medium text-gray-900">Tarjeta de Crédito/Débito</p>
              <p className="text-sm text-gray-500">Pago seguro con tarjeta</p>
            </div>
          </button>

          <button
            onClick={() => {
              setSelectedPaymentMethod('transfer');
              setShowPaymentModal(true);
            }}
            className="p-4 border rounded-lg hover:bg-gray-50 text-left flex items-center gap-3"
          >
            <Building2 className="w-6 h-6 text-gray-400" />
            <div>
              <p className="font-medium text-gray-900">Transferencia Bancaria</p>
              <p className="text-sm text-gray-500">Datos de nuestra cuenta</p>
            </div>
          </button>
        </div>
      </div>

      {/* Payment History */}
      <PaymentHistory />

      {/* Payment Modal */}
      {showPaymentModal && (
        <PaymentMethodModal
          type={selectedPaymentMethod}
          onClose={() => {
            setShowPaymentModal(false);
            setSelectedPaymentMethod(null);
          }}
        />
      )}
    </>
  );
}