import React from 'react';
import { Calendar, Clock, Users, CheckCircle } from 'lucide-react';
import AppointmentCalendar from '../components/appointments/AppointmentCalendar';
import UpcomingAppointments from '../components/appointments/UpcomingAppointments';

export default function Appointments() {
  return (
    <>
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Appointments</h1>
        <p className="text-gray-500 mt-1">Schedule and manage your appointments</p>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
        {[
          { label: "Today's Appointments", value: '8', icon: Calendar, color: 'bg-blue-500' },
          { label: 'Pending', value: '3', icon: Clock, color: 'bg-yellow-500' },
          { label: 'Total Clients', value: '124', icon: Users, color: 'bg-purple-500' },
          { label: 'Completed Today', value: '5', icon: CheckCircle, color: 'bg-green-500' },
        ].map((stat) => (
          <div key={stat.label} className="bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center gap-4">
              <div className={`${stat.color} p-3 rounded-lg`}>
                <stat.icon className="w-6 h-6 text-white" />
              </div>
              <div>
                <p className="text-sm text-gray-500">{stat.label}</p>
                <p className="text-2xl font-bold">{stat.value}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <AppointmentCalendar />
        </div>
        <div>
          <UpcomingAppointments />
        </div>
      </div>
    </>
  );
}