import React, { useState } from 'react';
import { X, Plus, HelpCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';

interface CreateSurveyModalProps {
  onClose: () => void;
}

interface Question {
  id: string;
  type: 'multiple' | 'rating' | 'text';
  text: string;
  options?: string[];
}

interface SurveyFormData {
  name: string;
  description: string;
  audience: string[];
  startDate: string;
  endDate: string;
  sendNotification: boolean;
}

export default function CreateSurveyModal({ onClose }: CreateSurveyModalProps) {
  const { register, handleSubmit } = useForm<SurveyFormData>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addQuestion = (type: Question['type']) => {
    setQuestions([
      ...questions,
      {
        id: Date.now().toString(),
        type,
        text: '',
        options: type === 'multiple' ? [''] : undefined,
      },
    ]);
  };

  const removeQuestion = (id: string) => {
    setQuestions(questions.filter((q) => q.id !== id));
  };

  const onSubmit = async (data: SurveyFormData) => {
    try {
      setIsSubmitting(true);
      // Aquí iría la lógica para crear la encuesta
      console.log('Encuesta creada:', { ...data, questions });
      onClose();
    } catch (error) {
      console.error('Error al crear la encuesta:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900">Crear Nueva Encuesta</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nombre de la Encuesta
              </label>
              <input
                type="text"
                {...register('name', { required: true })}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Descripción
              </label>
              <textarea
                {...register('description')}
                rows={3}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Audiencia Objetivo
              </label>
              <select
                multiple
                {...register('audience', { required: true })}
                className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              >
                <option value="all">Todos los clientes</option>
                <option value="vip">Clientes VIP</option>
                <option value="recent">Clientes Recientes</option>
              </select>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Fecha de Inicio
                </label>
                <input
                  type="date"
                  {...register('startDate', { required: true })}
                  className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Fecha de Fin
                </label>
                <input
                  type="date"
                  {...register('endDate', { required: true })}
                  className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                />
              </div>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-2">
                Preguntas
                <HelpCircle className="w-4 h-4 text-gray-400" />
              </h3>
              <div className="space-y-4 mb-4">
                {questions.map((question) => (
                  <div key={question.id} className="border rounded-lg p-4">
                    <div className="flex justify-between mb-2">
                      <span className="text-sm font-medium">
                        {question.type === 'multiple'
                          ? 'Selección Múltiple'
                          : question.type === 'rating'
                          ? 'Calificación'
                          : 'Respuesta Corta'}
                      </span>
                      <button
                        type="button"
                        onClick={() => removeQuestion(question.id)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                    <input
                      type="text"
                      value={question.text}
                      onChange={(e) =>
                        setQuestions(
                          questions.map((q) =>
                            q.id === question.id
                              ? { ...q, text: e.target.value }
                              : q
                          )
                        )
                      }
                      placeholder="Escribe tu pregunta..."
                      className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500 mb-2"
                    />
                    {question.type === 'multiple' && question.options && (
                      <div className="space-y-2">
                        {question.options.map((option, index) => (
                          <input
                            key={index}
                            type="text"
                            value={option}
                            onChange={(e) =>
                              setQuestions(
                                questions.map((q) =>
                                  q.id === question.id
                                    ? {
                                        ...q,
                                        options: q.options?.map((opt, i) =>
                                          i === index ? e.target.value : opt
                                        ),
                                      }
                                    : q
                                )
                              )
                            }
                            placeholder={`Opción ${index + 1}`}
                            className="w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                          />
                        ))}
                        <button
                          type="button"
                          onClick={() =>
                            setQuestions(
                              questions.map((q) =>
                                q.id === question.id
                                  ? {
                                      ...q,
                                      options: [...(q.options || []), ''],
                                    }
                                  : q
                              )
                            )
                          }
                          className="text-sm text-indigo-600 hover:text-indigo-700"
                        >
                          + Agregar opción
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={() => addQuestion('multiple')}
                  className="btn btn-secondary text-sm"
                >
                  + Selección Múltiple
                </button>
                <button
                  type="button"
                  onClick={() => addQuestion('rating')}
                  className="btn btn-secondary text-sm"
                >
                  + Calificación
                </button>
                <button
                  type="button"
                  onClick={() => addQuestion('text')}
                  className="btn btn-secondary text-sm"
                >
                  + Respuesta Corta
                </button>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                {...register('sendNotification')}
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label className="text-sm text-gray-700">
                Enviar notificación a los clientes seleccionados
              </label>
            </div>

            <div className="flex justify-end gap-2 pt-4 border-t">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                disabled={isSubmitting}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Creando...' : 'Crear Encuesta'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}