import React from 'react';
import { FileText, DollarSign, Clock, CheckCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function QuoteStats() {
  const { t } = useTranslation();

  const stats = [
    {
      label: 'Total Cotizaciones',
      value: '156',
      change: '+12',
      icon: FileText,
      color: 'bg-blue-500',
    },
    {
      label: 'Valor Total',
      value: '$45,678',
      change: '+$5,432',
      icon: DollarSign,
      color: 'bg-green-500',
    },
    {
      label: 'Pendientes',
      value: '23',
      change: '-3',
      icon: Clock,
      color: 'bg-yellow-500',
    },
    {
      label: 'Aceptadas',
      value: '89',
      change: '+8',
      icon: CheckCircle,
      color: 'bg-purple-500',
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      {stats.map((stat) => (
        <div
          key={stat.label}
          className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow"
        >
          <div className="flex items-center justify-between mb-4">
            <div className={`${stat.color} p-3 rounded-lg`}>
              <stat.icon className="w-6 h-6 text-white" />
            </div>
            <span className="text-green-500 text-sm font-medium">{stat.change}</span>
          </div>
          <h3 className="text-gray-500 text-sm font-medium">{stat.label}</h3>
          <p className="text-2xl font-bold text-gray-900 mt-1">{stat.value}</p>
        </div>
      ))}
    </div>
  );
}