import React from 'react';
import { Settings as SettingsIcon, User, Bell, Lock, Palette } from 'lucide-react';

export default function Settings() {
  return (
    <>
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Settings</h1>
        <p className="text-gray-500 mt-1">Manage your account and preferences</p>
      </header>

      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-6">
          <div className="space-y-6">
            {[
              { icon: User, label: 'Profile Settings', description: 'Update your personal information' },
              { icon: Bell, label: 'Notifications', description: 'Configure notification preferences' },
              { icon: Lock, label: 'Security', description: 'Manage your security settings' },
              { icon: Palette, label: 'Appearance', description: 'Customize the interface' },
            ].map((setting) => (
              <div
                key={setting.label}
                className="p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-colors"
              >
                <div className="flex items-center gap-4">
                  <div className="bg-gray-100 p-3 rounded-lg">
                    <setting.icon className="w-5 h-5 text-gray-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">{setting.label}</h3>
                    <p className="text-sm text-gray-500">{setting.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}