import { supabase } from './supabase';

export interface WhatsAppConfig {
  phone: string;
  token: string;
  permissions: {
    sendMessages: boolean;
    receiveMessages: boolean;
    readStatus: boolean;
    manageTemplates: boolean;
  };
  botConfig?: {
    enabled: boolean;
    gptModel: string;
    apiKey: string;
    systemPrompt: string;
    dataCollection: {
      enabled: boolean;
      spreadsheetId: string;
      sheetName: string;
      fields: Array<{
        name: string;
        type: 'text' | 'number' | 'date' | 'email' | 'phone';
        required: boolean;
        prompt: string;
      }>;
    };
    calendar: {
      enabled: boolean;
      calendarId: string;
      reminderSettings: {
        appointments: boolean;
        payments: boolean;
        followUps: boolean;
      };
    };
  };
}

export interface WhatsAppTemplate {
  id: string;
  name: string;
  content: string;
  variables: string[];
  status: 'active' | 'pending' | 'rejected';
}

export interface AutoResponse {
  id: string;
  trigger: string;
  response: string;
  isActive: boolean;
}

interface OpenAIResponse {
  choices: Array<{
    message: {
      content: string;
    };
  }>;
}

interface AppointmentDetails {
  summary: string;
  description: string;
  startTime: Date;
  endTime: Date;
  attendees: string[];
}

export async function saveWhatsAppConfig(userId: string, config: WhatsAppConfig) {
  try {
    const { error } = await supabase
      .from('whatsapp_config')
      .upsert({
        user_id: userId,
        phone_number: config.phone,
        api_token: config.token,
        permissions: config.permissions,
        bot_config: config.botConfig
      });

    if (error) throw error;
    return { success: true };
  } catch (error) {
    console.error('Error saving WhatsApp config:', error);
    return { success: false, error };
  }
}

export async function getWhatsAppConfig(userId: string): Promise<WhatsAppConfig | null> {
  try {
    const { data, error } = await supabase
      .from('whatsapp_config')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (error) throw error;
    
    if (!data) return null;

    return {
      phone: data.phone_number,
      token: data.api_token,
      permissions: data.permissions,
      botConfig: data.bot_config
    };
  } catch (error) {
    console.error('Error fetching WhatsApp config:', error);
    return null;
  }
}

export async function saveAutoResponse(userId: string, autoResponse: Omit<AutoResponse, 'id'>) {
  try {
    const { error } = await supabase
      .from('whatsapp_auto_responses')
      .insert({
        user_id: userId,
        trigger: autoResponse.trigger,
        response: autoResponse.response,
        is_active: autoResponse.isActive
      });

    if (error) throw error;
    return { success: true };
  } catch (error) {
    console.error('Error saving auto response:', error);
    return { success: false, error };
  }
}

export async function getAutoResponses(userId: string): Promise<AutoResponse[]> {
  try {
    const { data, error } = await supabase
      .from('whatsapp_auto_responses')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: true });

    if (error) throw error;

    return data.map(item => ({
      id: item.id,
      trigger: item.trigger,
      response: item.response,
      isActive: item.is_active
    }));
  } catch (error) {
    console.error('Error fetching auto responses:', error);
    return [];
  }
}

export async function saveTemplate(userId: string, template: Omit<WhatsAppTemplate, 'id' | 'status'>) {
  try {
    const { error } = await supabase
      .from('whatsapp_templates')
      .insert({
        user_id: userId,
        name: template.name,
        content: template.content,
        variables: template.variables,
        status: 'pending'
      });

    if (error) throw error;
    return { success: true };
  } catch (error) {
    console.error('Error saving template:', error);
    return { success: false, error };
  }
}

export async function getTemplates(userId: string): Promise<WhatsAppTemplate[]> {
  try {
    const { data, error } = await supabase
      .from('whatsapp_templates')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data.map(item => ({
      id: item.id,
      name: item.name,
      content: item.content,
      variables: item.variables,
      status: item.status
    }));
  } catch (error) {
    console.error('Error fetching templates:', error);
    return [];
  }
}

export async function processWhatsAppMessage(userId: string, message: {
  from: string;
  content: string;
  timestamp: Date;
}) {
  try {
    const config = await getWhatsAppConfig(userId);
    if (!config?.botConfig?.enabled) return { success: false, error: 'Bot not enabled' };

    // 1. Process with ChatGPT
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${config.botConfig.apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: config.botConfig.gptModel,
        messages: [
          { role: 'system', content: config.botConfig.systemPrompt },
          { role: 'user', content: message.content }
        ]
      })
    });

    if (!response.ok) {
      throw new Error(`OpenAI API error: ${response.statusText}`);
    }

    const gptResponse = await response.json() as OpenAIResponse;
    if (!gptResponse.choices?.[0]?.message?.content) {
      throw new Error('Invalid response from OpenAI API');
    }

    const assistantMessage = gptResponse.choices[0].message.content;

    // 2. Extract structured data if data collection is enabled
    if (config.botConfig.dataCollection?.enabled) {
      try {
        const extractedData = await extractStructuredData(
          message.content,
          config.botConfig.dataCollection.fields,
          config.botConfig.apiKey
        );
        
        if (extractedData) {
          await appendToGoogleSheet(
            config.botConfig.dataCollection.spreadsheetId,
            config.botConfig.dataCollection.sheetName,
            [extractedData]
          );
        }
      } catch (error) {
        console.error('Error processing data collection:', error);
        // Continue execution even if data collection fails
      }
    }

    // 3. Handle calendar events if calendar integration is enabled
    if (config.botConfig.calendar?.enabled) {
      try {
        const appointmentDetails = await extractAppointmentDetails(
          message.content,
          config.botConfig.apiKey
        );

        if (appointmentDetails) {
          await createCalendarEvent(
            config.botConfig.calendar.calendarId,
            appointmentDetails
          );
        }
      } catch (error) {
        console.error('Error processing calendar event:', error);
        // Continue execution even if calendar processing fails
      }
    }

    // 4. Save the interaction in the database
    const { error } = await supabase
      .from('whatsapp_interactions')
      .insert({
        user_id: userId,
        from_number: message.from,
        message: message.content,
        response: assistantMessage,
        timestamp: message.timestamp,
      });

    if (error) throw error;

    return {
      success: true,
      response: assistantMessage
    };
  } catch (error) {
    console.error('Error processing WhatsApp message:', error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : 'Unknown error occurred'
    };
  }
}

export async function extractStructuredData(
  message: string,
  fields: Array<{
    name: string;
    type: 'text' | 'number' | 'date' | 'email' | 'phone';
    required: boolean;
    prompt: string;
  }>,
  apiKey: string
): Promise<Record<string, any>> {
  try {
    // Implementar la extracción de datos usando GPT
    return {};
  } catch (error) {
    console.error('Error extracting structured data:', error);
    return {};
  }
}

export async function appendToGoogleSheet(
  spreadsheetId: string,
  sheetName: string,
  data: Record<string, any>[]
): Promise<void> {
  try {
    // Implementar la lógica para agregar datos a Google Sheets
  } catch (error) {
    console.error('Error appending to Google Sheet:', error);
  }
}

export async function createCalendarEvent(
  calendarId: string,
  appointmentDetails: AppointmentDetails
): Promise<void> {
  try {
    // Implementar la lógica para crear eventos en Google Calendar
  } catch (error) {
    console.error('Error creating calendar event:', error);
  }
}

export async function extractAppointmentDetails(
  message: string,
  apiKey: string
): Promise<AppointmentDetails | null> {
  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'gpt-4',
        messages: [
          {
            role: 'system',
            content: 'Extract appointment details from the following message. Return null if no appointment details found. Format: {"summary": string, "description": string, "startTime": ISO date string, "endTime": ISO date string, "attendees": string[]}'
          },
          { role: 'user', content: message }
        ]
      })
    });

    if (!response.ok) {
      throw new Error(`OpenAI API error: ${response.statusText}`);
    }

    const data = await response.json();
    if (!data.choices?.[0]?.message?.content) {
      return null;
    }

    const parsed = JSON.parse(data.choices[0].message.content);
    if (!parsed || typeof parsed !== 'object') {
      return null;
    }

    return {
      summary: String(parsed.summary || ''),
      description: String(parsed.description || ''),
      startTime: new Date(parsed.startTime),
      endTime: new Date(parsed.endTime),
      attendees: Array.isArray(parsed.attendees) ? parsed.attendees.map(String) : []
    };
  } catch (error) {
    console.error('Error extracting appointment details:', error);
    return null;
  }
}

export const handleApiError = (error: any) => {
  if (error.message?.includes('rate limit exceeded')) {
    return {
      error: 'Límite de solicitudes excedido. Por favor, intente nuevamente en unos minutos.',
      isRateLimit: true
    };
  }
  return {
    error: 'Ocurrió un error inesperado. Por favor, intente nuevamente.',
    isRateLimit: false
  };
};
