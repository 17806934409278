import React, { useState, useEffect } from 'react';
import { MessageSquare, Settings, Bell, Menu, Phone } from 'lucide-react';
import WhatsAppSetup from '../components/whatsapp/WhatsAppSetup';
import AutoResponder from '../components/whatsapp/AutoResponder';
import MenuBuilder from '../components/whatsapp/MenuBuilder';
import FAQSection from '../components/whatsapp/FAQSection';
import WhatsAppChat from '../components/whatsapp/WhatsAppChat';

const WHATSAPP_API = import.meta.env.VITE_API_URL || 'http://localhost:3001';

export default function WhatsApp() {
  const [activeTab, setActiveTab] = useState('setup');
  const [connectionStatus, setConnectionStatus] = useState('disconnected');

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await fetch(`${WHATSAPP_API}/status`);
        const data = await response.json();
        setConnectionStatus(data.status);
      } catch (error) {
        console.error('Error checking status:', error);
      }
    };

    const interval = setInterval(checkStatus, 5000);
    return () => clearInterval(interval);
  }, []);

  const tabs = [
    { id: 'setup', label: 'Setup', icon: Settings },
    { id: 'chat', label: 'Chat', icon: Phone },
    { id: 'autoresponder', label: 'Auto Responder', icon: Bell },
    { id: 'menu', label: 'Menu Builder', icon: Menu },
    { id: 'faq', label: 'FAQs', icon: MessageSquare },
  ];

  return (
    <>
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">WhatsApp Integration</h1>
        <p className="text-gray-500 mt-1">Configure your WhatsApp Business account</p>
        <div className={`mt-2 inline-flex items-center px-3 py-1 rounded-full text-sm ${
          connectionStatus === 'connected' 
            ? 'bg-green-100 text-green-800' 
            : connectionStatus === 'qr_ready'
            ? 'bg-yellow-100 text-yellow-800'
            : 'bg-red-100 text-red-800'
        }`}>
          Status: {connectionStatus}
        </div>
      </header>

      <div className="bg-white rounded-xl shadow-sm">
        <div className="border-b">
          <nav className="flex space-x-4 px-6" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center gap-2 px-4 py-3 text-sm font-medium border-b-2 ${
                  activeTab === tab.id
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                <tab.icon className="w-4 h-4" />
                {tab.label}
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6">
          {activeTab === 'setup' && <WhatsAppSetup apiUrl={WHATSAPP_API} connectionStatus={connectionStatus} />}
          {activeTab === 'chat' && <WhatsAppChat apiUrl={WHATSAPP_API} />}
          {activeTab === 'autoresponder' && <AutoResponder />}
          {activeTab === 'menu' && <MenuBuilder />}
          {activeTab === 'faq' && <FAQSection />}
        </div>
      </div>
    </>
  );
}