import React from 'react';
import { ShoppingBag, Package, Truck, CheckCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function OrderStats() {
  const { t } = useTranslation();

  const stats = [
    { label: t('orders.stats.newOrders'), value: '12', icon: ShoppingBag, color: 'bg-blue-500' },
    { label: t('orders.stats.processing'), value: '8', icon: Package, color: 'bg-yellow-500' },
    { label: t('orders.stats.shipping'), value: '5', icon: Truck, color: 'bg-purple-500' },
    { label: t('orders.stats.delivered'), value: '24', icon: CheckCircle, color: 'bg-green-500' },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
      {stats.map((stat) => (
        <div key={stat.label} className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center gap-4">
            <div className={`${stat.color} p-3 rounded-lg`}>
              <stat.icon className="w-6 h-6 text-white" />
            </div>
            <div>
              <p className="text-sm text-gray-500">{stat.label}</p>
              <p className="text-2xl font-bold">{stat.value}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}