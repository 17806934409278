import { supabase } from './supabase';

export interface Client {
  id: string;
  business_id: string;
  full_name: string;
  email: string;
  phone: string;
  status: 'active' | 'inactive';
  created_at: string;
  last_contact: string | null;
  notes?: string;
  address?: string;
}

export interface Order {
  id: string;
  client_id: string;
  business_id: string;
  status: 'pending' | 'completed' | 'cancelled';
  total: number;
  created_at: string;
}

export async function fetchClients(businessId: string, searchTerm: string = '') {
  try {
    const { data, error } = await supabase
      .from('clients')
      .select(`
        *,
        orders:orders(count)
      `)
      .eq('business_id', businessId)
      .ilike('full_name', `%${searchTerm}%`)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data.map(client => ({
      ...client,
      total_orders: client.orders[0]?.count || 0
    }));
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
}

export async function addClient(businessId: string, client: Omit<Client, 'id' | 'business_id' | 'created_at'>) {
  try {
    const { data, error } = await supabase
      .from('clients')
      .insert([{
        ...client,
        business_id: businessId,
        created_at: new Date().toISOString(),
        status: client.status || 'active'
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding client:', error);
    throw error;
  }
}

export async function updateClient(businessId: string, clientId: string, updates: Partial<Client>) {
  try {
    const { data, error } = await supabase
      .from('clients')
      .update(updates)
      .eq('id', clientId)
      .eq('business_id', businessId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating client:', error);
    throw error;
  }
}

export async function deleteClient(businessId: string, clientId: string) {
  try {
    const { error } = await supabase
      .from('clients')
      .delete()
      .eq('id', clientId)
      .eq('business_id', businessId);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error deleting client:', error);
    throw error;
  }
}

export async function addOrder(businessId: string, order: Omit<Order, 'id' | 'business_id' | 'created_at'>) {
  try {
    const { data, error } = await supabase
      .from('orders')
      .insert([{
        ...order,
        business_id: businessId,
        created_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding order:', error);
    throw error;
  }
}

export async function fetchClientOrders(businessId: string, clientId: string) {
  try {
    const { data, error } = await supabase
      .from('orders')
      .select('*')
      .eq('business_id', businessId)
      .eq('client_id', clientId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching client orders:', error);
    throw error;
  }
}
