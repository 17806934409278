import React, { useState } from 'react';
import { Bell, Clock, MessageSquare, Settings } from 'lucide-react';

export default function AutomationSettings() {
  const [automations, setAutomations] = useState([
    {
      id: 1,
      type: 'welcome',
      enabled: true,
      message: '¡Gracias por interactuar con nuestra campaña!',
    },
    {
      id: 2,
      type: 'reminder',
      enabled: false,
      message: '¡No te olvides de revisar nuestras ofertas!',
      delay: '24h',
    },
    {
      id: 3,
      type: 'conversion',
      enabled: true,
      message: '¡Gracias por tu compra!',
    },
  ]);

  const toggleAutomation = (id: number) => {
    setAutomations(automations.map(auto => 
      auto.id === id ? { ...auto, enabled: !auto.enabled } : auto
    ));
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-lg font-bold text-gray-900 mb-6">Automatización de Respuestas</h2>
      <div className="space-y-4">
        {automations.map((automation) => (
          <div key={automation.id} className="border rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                {automation.type === 'welcome' && <MessageSquare className="w-5 h-5 text-blue-500" />}
                {automation.type === 'reminder' && <Clock className="w-5 h-5 text-yellow-500" />}
                {automation.type === 'conversion' && <Bell className="w-5 h-5 text-green-500" />}
                <h3 className="font-medium text-gray-900">
                  {automation.type === 'welcome' && 'Mensaje de Bienvenida'}
                  {automation.type === 'reminder' && 'Recordatorio'}
                  {automation.type === 'conversion' && 'Confirmación de Conversión'}
                </h3>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={automation.enabled}
                  onChange={() => toggleAutomation(automation.id)}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <textarea
              value={automation.message}
              onChange={(e) => {
                setAutomations(automations.map(auto =>
                  auto.id === automation.id ? { ...auto, message: e.target.value } : auto
                ));
              }}
              className="w-full mt-2 text-sm rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
              rows={2}
            />
            {automation.type === 'reminder' && (
              <div className="mt-2 flex items-center gap-2">
                <Clock className="w-4 h-4 text-gray-400" />
                <select
                  value={automation.delay}
                  onChange={(e) => {
                    setAutomations(automations.map(auto =>
                      auto.id === automation.id ? { ...auto, delay: e.target.value } : auto
                    ));
                  }}
                  className="text-sm border rounded-lg px-2 py-1"
                >
                  <option value="24h">24 horas</option>
                  <option value="48h">48 horas</option>
                  <option value="72h">72 horas</option>
                </select>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}