import React from 'react';
import { MenuItem } from './types';

interface MenuPreviewProps {
  items: MenuItem[];
}

export function MenuPreview({ items }: MenuPreviewProps) {
  const renderMenuItem = (item: MenuItem) => {
    switch (item.type) {
      case 'text':
        return (
          <div className="p-3 bg-white rounded-lg shadow-sm mb-2">
            <p className="text-gray-700">{item.content || item.title}</p>
          </div>
        );
      case 'image':
        return (
          <div className="p-3 bg-white rounded-lg shadow-sm mb-2">
            {item.imageUrl ? (
              <img
                src={item.imageUrl}
                alt={item.title}
                className="w-full h-32 object-cover rounded-md"
              />
            ) : (
              <div className="w-full h-32 bg-gray-200 rounded-md flex items-center justify-center">
                <span className="text-gray-400">Vista previa de imagen</span>
              </div>
            )}
          </div>
        );
      case 'link':
        return (
          <div className="p-3 bg-white rounded-lg shadow-sm mb-2">
            <a
              href={item.linkUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              {item.title}
            </a>
          </div>
        );
      case 'button':
        return (
          <div className="p-3 bg-white rounded-lg shadow-sm mb-2">
            <button className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
              {item.title}
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-50 p-4 rounded-lg">
      <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-4">
        <h3 className="text-sm font-medium text-green-800 mb-2">Vista previa de WhatsApp</h3>
        <p className="text-xs text-green-600">
          Así es como los usuarios verán tu menú en WhatsApp
        </p>
      </div>

      <div className="space-y-4">
        {items.map((item) => (
          <div key={item.id}>{renderMenuItem(item)}</div>
        ))}
      </div>

      {items.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-500">
            Agrega elementos al menú para ver la vista previa
          </p>
        </div>
      )}
    </div>
  );
}
