import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminStats, UserData, SystemModule, Notification } from '../types/admin';

interface AdminState {
  stats: AdminStats;
  users: UserData[];
  modules: SystemModule[];
  notifications: Notification[];
  selectedUser: UserData | null;
}

const initialState: AdminState = {
  stats: {
    totalUsers: 2847,
    activeUsers: 2156,
    totalRevenue: 1284700,
    monthlyRevenue: 156000,
    pendingOrders: 23,
    completedOrders: 156,
  },
  users: [
    {
      id: '1',
      name: 'Juan Pérez',
      email: 'juan@example.com',
      type: 'user',
      status: 'active',
      plan: 'emprendedor',
      createdAt: '2024-03-01',
      lastLogin: '2024-03-14',
    },
    {
      id: '2',
      name: 'María González',
      email: 'maria@example.com',
      type: 'user',
      status: 'active',
      plan: 'pyme',
      createdAt: '2024-02-15',
      lastLogin: '2024-03-13',
    },
  ],
  modules: [
    {
      id: 'surveys',
      name: 'Encuestas',
      enabled: true,
      description: 'Módulo de encuestas y feedback'
    },
    {
      id: 'quotes',
      name: 'Cotizaciones',
      enabled: true,
      description: 'Sistema de cotizaciones'
    },
    {
      id: 'marketing',
      name: 'Marketing',
      enabled: true,
      description: 'Herramientas de marketing'
    }
  ],
  notifications: [
    {
      id: '1',
      type: 'info',
      message: 'Nuevo usuario registrado',
      timestamp: new Date().toISOString(),
      read: false,
    },
    {
      id: '2',
      type: 'warning',
      message: 'Actualización del sistema pendiente',
      timestamp: new Date().toISOString(),
      read: false,
    },
  ],
  selectedUser: null,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setStats: (state, action: PayloadAction<AdminStats>) => {
      state.stats = action.payload;
    },
    setUsers: (state, action: PayloadAction<UserData[]>) => {
      state.users = action.payload;
    },
    updateUser: (state, action: PayloadAction<UserData>) => {
      const index = state.users.findIndex(user => user.id === action.payload.id);
      if (index !== -1) {
        state.users[index] = action.payload;
      }
    },
    toggleModule: (state, action: PayloadAction<string>) => {
      const module = state.modules.find(m => m.id === action.payload);
      if (module) {
        module.enabled = !module.enabled;
      }
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.unshift(action.payload);
    },
    markNotificationAsRead: (state, action: PayloadAction<string>) => {
      const notification = state.notifications.find(n => n.id === action.payload);
      if (notification) {
        notification.read = true;
      }
    },
    setSelectedUser: (state, action: PayloadAction<UserData | null>) => {
      state.selectedUser = action.payload;
    },
  },
});

export const {
  setStats,
  setUsers,
  updateUser,
  toggleModule,
  addNotification,
  markNotificationAsRead,
  setSelectedUser,
} = adminSlice.actions;

export default adminSlice.reducer;