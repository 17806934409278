export default {
  nav: {
    dashboard: 'Panel Principal',
    whatsapp: 'WhatsApp',
    orders: 'Pedidos',
    crm: 'Gestión de Clientes',
    appointments: 'Citas',
    marketing: 'Marketing',
    surveys: 'Encuestas',
    quotes: 'Cotizaciones',
    payments: 'Pagos',
    settings: 'Configuración',
    logout: 'Cerrar Sesión'
  },
  dashboard: {
    welcome: '¡Bienvenido de nuevo, {name}!',
    subtitle: 'Esto es lo que está pasando en tu negocio hoy.',
    stats: {
      totalCustomers: 'Total Clientes',
      activeOrders: 'Pedidos Activos',
      todayAppointments: 'Citas de Hoy',
      revenue: 'Ingresos'
    },
    recentActivity: {
      title: 'Actividad Reciente',
      newMessage: 'Nuevo Mensaje de WhatsApp',
      newOrder: 'Nuevo Pedido Recibido',
      appointmentConfirmed: 'Cita Confirmada',
      newCustomer: 'Nuevo Cliente'
    },
    revenueChart: {
      title: 'Gráfico de Ingresos',
      placeholder: 'Aquí se mostrará el gráfico de ingresos'
    }
  },
  whatsapp: {
    title: 'Integración de WhatsApp',
    subtitle: 'Configura tu cuenta de WhatsApp Business',
    setup: {
      title: 'Configuración',
      phoneNumber: 'Número de Teléfono',
      apiToken: 'Token de API',
      permissions: 'Permisos',
      connect: 'Conectar y Verificar'
    }
  },
  orders: {
    title: 'Gestión de Pedidos',
    subtitle: 'Seguimiento y gestión de pedidos',
    stats: {
      newOrders: 'Nuevos Pedidos',
      processing: 'En Proceso',
      shipping: 'En Envío',
      delivered: 'Entregados'
    }
  },
  crm: {
    title: 'Gestión de Clientes',
    subtitle: 'Administra y analiza las interacciones con clientes',
    stats: {
      totalCustomers: 'Total Clientes',
      vipCustomers: 'Clientes VIP',
      newThisMonth: 'Nuevos este Mes',
      followUps: 'Seguimientos'
    }
  },
  appointments: {
    title: 'Citas',
    subtitle: 'Programa y gestiona tus citas',
    stats: {
      todayAppointments: 'Citas de Hoy',
      pending: 'Pendientes',
      totalClients: 'Total Clientes',
      completedToday: 'Completadas Hoy'
    }
  },
  marketing: {
    title: 'Marketing Automatizado',
    subtitle: 'Gestiona tus campañas y automatizaciones',
    stats: {
      activeCampaigns: 'Campañas Activas',
      targetAudience: 'Audiencia Objetivo',
      messagesSent: 'Mensajes Enviados',
      conversionRate: 'Tasa de Conversión'
    }
  },
  surveys: {
    title: 'Encuestas y Feedback',
    subtitle: 'Crea y gestiona encuestas de clientes',
    stats: {
      activeSurveys: 'Encuestas Activas',
      totalResponses: 'Total Respuestas',
      completionRate: 'Tasa de Finalización',
      avgRating: 'Calificación Promedio'
    }
  },
  quotes: {
    title: 'Cotizaciones',
    subtitle: 'Crea y rastrea cotizaciones de clientes',
    stats: {
      totalQuotes: 'Total Cotizaciones',
      value: 'Valor',
      pending: 'Pendientes',
      accepted: 'Aceptadas'
    }
  },
  payments: {
    title: 'Pagos',
    subtitle: 'Rastrea y gestiona pagos',
    stats: {
      totalRevenue: 'Ingresos Totales',
      pending: 'Pendientes',
      successful: 'Exitosos',
      failed: 'Fallidos'
    }
  },
  settings: {
    title: 'Configuración',
    subtitle: 'Gestiona tu cuenta y preferencias',
    sections: {
      profile: 'Perfil',
      notifications: 'Notificaciones',
      security: 'Seguridad',
      appearance: 'Apariencia'
    }
  },
  common: {
    loading: 'Cargando...',
    error: 'Ha ocurrido un error',
    save: 'Guardar',
    cancel: 'Cancelar',
    edit: 'Editar',
    delete: 'Eliminar',
    view: 'Ver',
    status: {
      active: 'Activo',
      inactive: 'Inactivo',
      pending: 'Pendiente',
      completed: 'Completado'
    }
  }
};